h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    font-weight: $headings-font-weight;
    font-family: $headings-font-family;
    text-transform: uppercase;
    color: $headings-color;
    line-height: $headings-line-height;
}

h1, .h1 {
    font-size: rem(40px);
    margin-bottom: $grid-gutter-width;

    @include screen('xs-min') {
        font-size: $font-size-h2;
    }

    @include screen('sm-min') {
        font-size: $font-size-h1;
        margin-bottom: 50px;
    }

    &--lg {
        font-size: rem(50px);

        @include screen('sm-min') {
            font-size: rem(100px);
        }
    }
}

h2, .h2 {
    font-size: rem(35px);
    margin-bottom: 12px;

    @include screen('xs-min') {
        font-size: $font-size-h3;
    }

    @include screen('sm-min') {
        font-size: $font-size-h2;
        margin-bottom: $grid-gutter-width;
    }
}

h3, .h3 {
    font-size: $font-size-h4;
    margin-bottom: $grid-gutter-width;

    @include screen('sm-min') {
        font-size: $font-size-h3;
        margin-bottom: $grid-gutter-width;
    }
}

h4, .h4 {
    font-size: $font-size-h4;
    margin-bottom: 20px;
}

.h2-with-subline {
    margin-bottom: $grid-gutter-width;

    h2 {
        margin-bottom: 0;
    }

    p {
        @extend .h3;
        font-size: rem(30px);
    }
}

p {
    margin-bottom: $grid-gutter-width;
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

sup, sub {
   position: relative;
   vertical-align: baseline;
   top: -0.4em;
   font-size: 75%;
}

sub {
    top: 0.4em;
}

.txt-center {
    text-align: center;
}

.txt-vcenter {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    margin-right: 14px;
}

.txt-right {
    text-align: right;
}

.txt-danger {
    color: $state-danger;
}

.txt-underline {
    text-decoration: underline;
}

.txt-lg {
    font-size: rem(18px);

    @include screen('sm-min') {
        font-size: rem(20px);
    }
}

.txt-sm {
    font-size: rem(14px);
}
