.pagination {
    @extend .list-box;

    .btn {
        width: 55px;
        height: 50px;
        margin-bottom: 0;
        padding: 8px 10px;
        line-height: 34px;
    }

    .icon {
        line-height: 1;
    }
}
