.collapse:not(.navbar-collapse) {
    max-height: 0;
    transition: max-height .6s ease-out;
    overflow: hidden;

    &.in {
        max-height: 600px;
    }
}

.panel {
    margin-bottom: 10px;

    &__icon {
        width: 20px;
        height: 20px;
        fill: $gray-light;
        transition: fill .3s;
        margin-right: 10px;
    }

    &__arrow {
        font-size: rem(24px);
        float: right;
        fill: $brand-primary;
        transition: transform .3s;
    }

    &__heading {
        position: relative;
        z-index: 5;

        .panel__title {
            color: $text-color;
            font-weight: normal;
            margin: 5px 0 0;
            font-size: $font-size-base;
            max-width: 80%;

            &.panel__title--bold {
                text-transform: none;
                font-weight: bold;
            }
        }

        .icon,
        .panel__title {
            display: inline-block;
            vertical-align: middle;
        }

        button {
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
            padding: 19px 20px;
            background: $gray-lighter;
            border-radius: $border-radius-base;
            text-align: left;
            transition: background .3s;

            &:hover,
            &[aria-expanded="true"] {
                background: $gray-light;

                .panel__icon {
                    fill: $text-color;
                }
            }

            &[aria-expanded="true"] {
                .panel__arrow {
                    transform: rotate(-180deg);
                }
            }
        }
    }


    &__collapse {
        background: $gray-light;
        border-bottom-left-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        padding: 0 20px 0 20px;
        transform: translateY(-1 * $border-radius-base);
        transition: margin-bottom .4s;

        p:last-child {
            margin-bottom: $grid-gutter-width;
        }
    }
}
