.modal {
    position: fixed;
    visibility: hidden;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: rgba(0,0,0, .6);
    z-index: 999;
    transition: opacity .2s;

    &__header {
        min-height: 50px;

        @include screen('sm-min') {
            text-align: right;
            padding-right: 40px;
        }

        @include screen('md-min') {
            min-height: 60px;
        }
    }


    &__content {
        position: relative;
        display: block;
        top: 100px;
        left: 50%;
        width: calc(100% - #{$grid-gutter-width});
        opacity: 0;
        padding: 20px 30px 30px;
        margin-bottom: 60px;
        background-color: #fff;
        border: 1px solid $gray-light;
        transform: translate(-50%, 80px);
        transition: transform .4s ease-out, opacity .4s ease-out;

        @include screen('md-min') {
            padding: 32px 50px 50px;
        }

        @include screen('sm-min') {
            max-width: 90%;
        }

        @include screen('md-min') {
            max-width: 1000px;
        }
    }

    &__controls {
        // display: none;
        position: absolute;
        top: 50%;
        left: 30px;
        right: 30px;
        transform: translateY(-50%);


        @include screen('md-min') {
            left: 50px;
            right: 50px;
        }

        @include screen('lg-min') {
            left: -60px;
            right: -60px;
        }
    }

    img {
        border: 1px solid $gray-border;
    }

    .carousel-control {
        position: absolute;
        left: -39px;
        width: 60px;
        height: 50px;
        padding: 0;
        margin-top: -6px;
        margin-left: -5px;
        font-size: rem(18px);
        text-decoration: none;
        //color: #fff;
        transition: transform .2s;

        &:hover {
            transform: translateX(-4px);
        }

        &.right {
            left: auto;
            right: -46px;

            &:hover {
                transform: translateX(4px);
            }
        }

        @include screen('md-min') {
            left: -50px;
            font-size: rem(26px);

            &.right {
                left: auto;
                right: -52px;
            }
        }

        @include screen('lg-min') {
            color: #fff;
            left: 0;

            &.right {
                left: auto;
                right: 0;
            }
        }
    }

    &__title {
        text-transform: uppercase;
        font-size: rem(16px);
        font-weight: bold;
        margin-bottom: 20px;
        min-height: 20px;
    }


    &.show {
        visibility: visible;
        opacity: 1;
        overflow: scroll;

        .modal__content {
            opacity: 1;
            transform: translate(-50%, 0);
        }
    }

    /* The Close Button */
    .close {
        position: absolute;
        top: 32px;
        right: 30px;
        color: $text-color;
        font-size: 24px;
        font-weight: bold;
        text-decoration: none;

        @include screen('md-min') {
            right: 45px;
        }

        &:hover,
        &:focus {
            color: $brand-primary;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
