form {
    .form-control {
        display: block;
        width: 100%;
        padding: 14px 16px;
        font-size: rem(16px);
        font-family: $font-family-sans-serif;
        line-height: $line-height-base;
        color: $text-color;
        border: 1px solid $form-border;
        background: $form-bg;
        border-radius: $border-radius-base;
        height: 54px;
        transition: border .2s, box-shadow .2s;

        &--narrow {
            width: 80px;

            @include screen('xs-min') {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &:focus {
            border-color: $brand-primary;
        }
    }

    input.btn {
        -webkit-appearance: none;
        border-bottom: 1px solid $brand-primary;
        border-right: 1px solid $brand-primary;
        border-color: $brand-primary;
        cursor: pointer;
    }

    label,
    .label {
        display: block;
        margin-bottom: 2px;
        font-size: rem(16px);
    }

    textarea.form-control {
        height: auto;
    }

    .form-group {
        margin-bottom: 6px;
    }

    .input-group {
        display: flex;
    }

    .has-error {
        .checkbox,
        .checkbox-inline,
        .control-label,
        .help-block,
        .radio,
        .radio-inline,
        &.checkbox label,
        &.checkbox-inline label,
        &.radio label,
        &.radio-inline label,
        label {
            color: $state-danger;
        }

        .form-control {
            border-color: $state-danger;
        }
    }

    fieldset {
        margin-bottom: 25px;
    }

    legend:not(.label) {
        @extend .h4;
        margin-bottom: 10px;
    }

    .form-inline,
    &.form-inline {
        @include screen('xs-min') {
            width: 380px;
            max-width: 100%;

            .form-group,
            .radio {
                //width: calc(50% - 23px);
                display: inline-block;
                vertical-align: top;
                margin-right: 20px;

                @include screen('sm-max') {
                    //width: calc(50% - 13px);

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }

            .radio {
                @include screen('sm-max') {
                    width: calc(50% - 13px);
                }
            }
        }
    }

    .btn-tooltip {
        position: absolute;
        left: 100%;
        top: 0;
    }
}


/** Custom Select **/

.custom-select {
    position: relative;
    display: block;
    min-width: 94px;
}

.custom-select select {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    opacity: 0.001;
}

.custom-select select,
.custom-select .btn-select {
    padding: 14px 70px 14px 16px;
    cursor: pointer;
}

.custom-select .btn-select {
    position: relative;
    display: block;
    height: 54px;
    font-size: rem(16px);
    background: #fff;
    text-align: left;
    border-radius: $border-radius-base;
    border: 1px solid $form-border;
    background: $form-bg;
    color: $gray-dark;
    @include icon(arrow-down, after);

    &:before {
        content: " ";
        display: block;
        background: $form-border;
        width: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 54px;
    }

    &:after {
        color: $gray-dark;
        display: block;
        position: absolute;
        top: 11px;
        right: 20px;
        font-size: rem(21px);
    }
}

.custom-select .has-error {
    border: 1px solid $state-danger;
}

.btn-select.btn-focus {
    border-color: $brand-primary;
}

.custom-select .label-text {
    display: inline-block;
    padding: 0;
}

/** CUSTOM RADIO BUTTONS **/

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: 36px;
}

.form-inline .radio,
.radio {
    margin-top: 0;
    padding-left: 32px;
    position: relative;
    margin-bottom: 12px;

    + .js-form-type-radio {
        margin-top: $grid-gutter-width--sm;
    }

    input[type="radio"] {
        @extend .sr-only;
    }

    label {
        padding-left: 0;
        font-weight: normal;
        cursor: pointer;
        display: inline-block;
        font-style: normal;
        transition: color .2s;

        &:before,
        &:after {
            position: absolute;
            content: "";
            display: inline-block;
        }

        &:before{
            height: 22px;
            width: 22px;
            background: $form-bg;
            border: 1px solid $form-border;
            border-radius: 50%;
            left: 0;
            margin-left: 0;
            top: 1px;
        }

        &:after {
            background: $brand-primary;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            left: 7px;
            top: 8px;
            opacity: 0;
            transform: scale(.6);
            transition: transform .2s, opacity .1s;
        }
    }

    input[type="radio"]:focus + label {
        &:before {
            border-color: $brand-primary;
        }
    }

    input[type="radio"]:checked + label {
        &::after {
            opacity: 1;
            transform: scale(1);
        }
    }
}


/** CUSTOM CHECKBOXES **/
.checkbox,
.checkbox + .checkbox {
    margin-top: 0;
}

.form-inline .checkbox,
.checkbox {
    input[type="checkbox"] {
        @extend .sr-only;
    }

    label {
        position: relative;
        display: inline-block;
        padding: 9px 0 4px 48px;
        margin-top: 15px;
        cursor: pointer;
        font-size: $font-size-h5;
        font-weight: 500;
        font-style: normal;
        transition: color .2s;

        @include screen('breakpoint-survey-max') {
            padding-top: 6px;
        }

        a {
            text-decoration: underline;
        }

        p {
            margin-bottom: 0;
        }

        @include icon('check', after);

        &:before {
            position: absolute;
            content: "";
            display: inline-block;
            height: 26px;
            width: 26px;
            border: 1px solid $form-border;
            background: $form-bg;
            left: 0px;
            top: 6px;
        }

        &:after {
            position: absolute;
            opacity: 0;
            left: 5.5px;
            top: 8.5px;
            transform: scale(.6);
            transition: transform .2s, opacity .1s;
        }

        &.has-error {
            &:before {
                border-color: $state-danger;
            }
        }
    }

    input[type="checkbox"]:focus + label {
        &:before {
            border-color: #cedae0;
            box-shadow: 0 0 .4em rgba(200,200,200,.8);
        }
    }

    input[type="checkbox"]:checked + label {

        &::after {
            color: $brand-primary;
            opacity: 1;
            transform: scale(1);
        }
    }
}
