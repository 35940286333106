.box {
    background: $gray-lighter;
    display: block;
    color: $brand-primary;
    height: 0;
    text-align: center;
    padding-bottom: 100%;
    position: relative;
    margin-bottom: $grid-gutter-width;

    img {
        max-width: 60%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--padded {
        height: auto;
        padding: 0 18px 15px;
        text-align: left;
        color: $text-color;

    }
}