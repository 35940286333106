.teardrop {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border-bottom-right-radius: 0;
    background: $brand-primary;
    color: #fff;
    display: block;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include screen('sm-min') {
        width: 80px;
        height: 80px;
    }

    @include screen('md-min') {
        width: 120px;
        height: 120px;
    }

    .icon {
        font-size: rem(20px);

        @include screen('sm-min') {
            font-size: rem(24px);
        }

        @include screen('md-min') {
            font-size: rem(30px);
        }
    }

    & > * {
        @include screen('sm-min') {
            margin-bottom: 0;
        }
    }

    &--lg {
        width: 220px;
        height: 220px;
        background: $text-color;

        @include screen('lg-min') {
            width: 255px;
            height: 255px;
        }

        @include screen('sm-max') {
            display: block;
            height: auto;
            width: auto;
            background-color: transparent;
            text-align: left;
            color: inherit;
        }
    }
}
