a,
button {
    cursor: pointer;
    color: $link-color;
    text-decoration: $link-hover-decoration;
    transition: color .2s;

    &:hover {
        color: $link-hover-color;
    }
}

p a {
    text-decoration: underline;
}

button {
    border: none;
    padding: 0;
    background: none;
}

a[href^=tel] {
    cursor: default;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
}

:focus,
a:focus,
button:focus,
:focus:active,
button:focus:active,
.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: none;
    box-shadow: none;
}

[data-whatinput="keyboard"] :focus,
[data-whatinput="keyboard"] :focus:active,
[data-whatinput="keyboard"] button:focus,
[data-whatinput="keyboard"] .btn:focus {
    outline: 3px solid;
    outline: auto -webkit-focus-ring-color;
    outline-color: #5b9dd9;
}

[data-whatinput="keyboard"] button.highlight {
    outline: 3px dotted;
    outline-color: #5b9dd9;
}

.show-on-focus {
    @extend .sr-only;

    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: auto;
        height: auto;
        color: #fff;
        background: $brand-primary;
        clip: unset;
        clip-path: none;
        z-index: 1500;
        padding: 15px 30px;
    }
}

.btn {
    position: relative;
    display: inline-block;
    margin-bottom: 12px;
    padding: 7px 20px;
    background: $brand-primary;
    line-height: 1.2;
    color: #fff;
    font-size: $font-size-base;
    text-align: center;
    text-decoration: none;
    white-space: normal;
    border-radius: $border-radius-base;
    transition: background .2s ease-in, color .2s ease-in;

    @include screen('sm-min') {
        //min-width: 380px;
        border-bottom: 34px solid #fff;
        border-right: 20px solid #fff;

        @include icon(arrow-right-long, after);

        &:after {
            color: $text-color;
            font-size: rem(17px);
            position: absolute;
            right: -20px;
            bottom: -34px;
            transition: transform .25s ease-out;

            @include screen('lg-min') {
                right: -30px;
            }
        }

        &.full-width {
            width: calc(100% + 20px);
        }
    }

    .active &,
    &:hover {
        background: $link-hover-color;
        color: #fff;

        &:after {
            color: $link-hover-color;
            transform: translateX(8px);
        }
    }

    &--sm {
        border-bottom: none;
        border-right: none;

        &:after {
            content: none;
        }
    }

    &--lg {
        padding-top: 14px;
        padding-bottom: 14px;
    }

    &--icon {
        width: 60px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;

        &:after {
            display: none;
        }
    }

    &--light {
        background-color: #fff;
        color: $brand-primary;
        border: 1px solid $brand-primary;

        &:after {
            content: none;
        }

        &.active {
            border-color: $link-hover-color;
        }

        &:hover {
            color: #fff;
            background-color: $brand-primary;
        }
    }

    &--back:not(.btn--sm) {
        @include screen('sm-min') {
            border: none;
            margin-bottom: 50px;
            @include icon(arrow-left-long, after);

            &:after {
                right:auto;
                left: -20px;

                @include screen('lg-min') {
                    right: auto;
                    left: -30px;
                }
            }

            .active &,
            &:hover {
                &:after {
                    transform: translateX(-8px);
                }
            }
        }
    }
}

.btn-square {
    width: 50px;
    height: 50px;
    line-height: 48px;
    text-align: center;
    background: $brand-primary;
    color: #fff;
    font-size: rem(18px);

    &:hover {
        background: $link-hover-color;
        color: #fff;
    }
    &.pause {
        .icon-play {
            display: none;
        }

        &.play {
            .icon-play {
                display: block;
            }

            .icon-pause {
                display: none;
            }
        }
    }
}

.btn-icon {
    background: $brand-primary;
    color: #fff;
    font-size: rem(21px);
    width: 60px;

    &:hover {
        background: $link-hover-color;
        color: #fff;
    }
}

.btn-tooltip {
    display: block;
    padding: 10px;
    position: relative;
    font-size: $font-size-base;
    text-decoration: underline;
}

.btn-teardrop {
    @extend .teardrop;
    margin-bottom: 12px;
    transition: background .3s, color .3s;

    &:hover {
        background: $link-hover-color;
        color: #fff;
    }
}

.link-block {
    display: block;
    margin-bottom: 40px;

    h2, h3 {
        margin-bottom: 10px;
    }

    p {
        color: $text-color;
    }

    strong {
        color: $brand-primary;
    }
}

.link-inline {
    color: $link-color;
    text-decoration: underline;

    &:hover {
        color: $link-hover-color;
    }
}

.btn-group {
    @include screen('sm-min') {
        text-align: right;

        > button,
        > a {
            vertical-align: top;
            margin-right: $grid-gutter-width;
            border: 1px solid $brand-primary;
        }
    }
}
