img {
    vertical-align: middle;
}

.img-cover {
    width: 100%;
    height: auto;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    margin-bottom: $grid-gutter-width;
}

figure {
    margin-bottom: $grid-gutter-width;
}

.img-zoomable {
    display: block;
    border: 1px solid $gray-border;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    img {
        margin-bottom: 0;
        transition: transform .4s ease-out;
    }

    .icon {
        position: absolute;
        right: 15px;
        bottom: 12px;
        color: #fff;
        font-size: rem(16px);
        transition: color .2s;
    }

    @include screen('sm-max') {
        pointer-events: none;

        .icon {
            display: none;
        }
    }

    @include screen('sm-min') {
        &:hover {
            cursor: pointer;

            img {
                transform: scale(1.05);
            }

            .icon {
                color: $brand-primary;
            }
        }
    }
}

.gallery {
    list-style: none;
    margin-bottom: 45px;
    margin-right: -15px;

    @include screen('lg-max') {
        margin-right: -10px;
    }

    li {
        display: inline-block;
        position: relative;
        margin-right: $grid-gutter-width;
        //max-width: 250px;

        @include screen('lg-max') {
            margin-right: $grid-gutter-width--sm;
        }
    }

    button {
        text-decoration: none;
        color: $text-color;
        text-align: left;
        font-size: rem(15px);

        pointer-events: initial;

        .icon {
            display: block;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        border: 1px solid $gray-border;
        transition: transform .2s;
    }

    li.selected,
    li:hover {
        button {
            color: $text-color;
        }
    }
}


.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

    &--4by3 {
        padding-bottom: 75%;
    }

    &--16by9 {
        padding-bottom: 56.25%;
    }

    &__item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}