.list {
    &-inline {
        list-style: none;
        margin-left: -6px;
        margin-right: -9px;

        > li {
            display: inline-block;
            padding-left: 6px;
            padding-right: 9px;
        }
    }

    &-divided {
        @extend .list-inline;

        > li:not(:last-child) {
            border-right: 1px solid $text-color;
        }
    }

    &-spread {
        li {
            display: block;
        }

        //@include screen('xs-min') {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            li {
                flex-basis: 130px;
                flex-grow: 0;
                flex-shrink: 1;
                margin-left: ($grid-gutter-width--sm/2);
                margin-right: ($grid-gutter-width--sm/2);

                img {
                    max-width: 100%;
                }

                @include screen('sm-min') {
                    flex-basis: 180px;
                    margin-left: $grid-gutter-width--sm;
                    margin-right: $grid-gutter-width--sm;
                }
            }
       // }
    }

    &-box {
        display: inline-block;
        // margin-right: 20px;
        margin: $grid-gutter-width -4px;

        li {
            display: inline-block;
            margin: 0 4px;
        }

        .icon {
            font-size: rem(11px);
            vertical-align: middle;
        }

        .btn {
            min-width: auto;
            &:after {
                content: none;
            }
        }
    }

    &-social {
        @extend .list-inline;

        > li {
            padding-right: 6px;
        }

        @include screen('sm-min') {
            margin: -45px -24px 45px -10px;

            > li {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}


main ul:not([class^="list"]):not(.tabs__nav):not(.gallery):not(.carousel__dots):not(.pagination) {
    list-style: none;
    padding-left: 14px;
    margin-bottom: 30px;

    li {
        text-indent: -13px;

        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 3px;
            height: 3px;
            margin-right: 9px;
            margin-top: -1px;
            background-color: $brand-primary;
            border-radius: 50%;
        }
    }
}

main ol:not(.breadcrumb) {
    list-style: none;
    margin-bottom: 30px;

    li {
        counter-increment: step-counter;
        margin-bottom: 10px;
        padding-left: 18px;
        position: relative;

        &:before {
            content: counter(step-counter) '.';
            position: absolute;
            left: 0;
            top: 0;
            color: $brand-primary;
        }
    }
}
