.cart {
    #checkout-step-payment-method {
        label a, a label {
            color: $gray-darker;
        }
        label {
            color: $brand-primary;
        }

    }
}

.paypal-info {
    border: 1px solid #d0bc8d;
    padding: 10px;
    font-size: 16px;
    background-color: #fbfbfb;
    margin: 10px 4px 0px 0px;
    box-shadow: 0px 0px 5px #ddd;
}
