header {
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    background: #fff;
    transition: transform .5s, box-shadow .3s, background .3s;

    a,
    button {
        font-size: $font-size-base;
        font-family: $font-family-sans-serif;
        line-height: $line-height-base;
        text-decoration: none;

        &:hover .icon {
            fill: $brand-primary;
        }
    }

    a {
        color: inherit;
    }

    .active a {
        color: $brand-primary;
    }

    .dropdown__toggle {
        &:after {
            content: '';
            display: block;
            position: absolute;
            right: -15px;
            top: 10px;
            border-left: 4.5px solid transparent;
            border-right: 4.5px solid transparent;
            border-top: 5px solid $text-color;
            transition: transform .2s;
        }

        &:hover:after {
            border-top: 5px solid $brand-primary;
        }
    }

    .navbar-header {
        display: block;
        margin-top: 64px;
    }

    .navbar-nav {
        a,
        button {
            display: block;
        }

        >li>a {
            position: relative;
        }
    }



    @include screen('breakpoint-nav-max') {

        .navbar-collapse {
            max-height: 0 !important;
            overflow: hidden;
            transition: max-height .3s;

            &.in {
                overflow: auto;
                max-height: calc(100vh - 123px) !important;

                //bug on iphone5 Safari
                min-height: 290px;
            }
        }

        .navbar-header {
            margin-top: 4px;

            @include screen('sm-min') {
                margin: 15px 0;
            }

            img {
                width: 100px;

                @include screen('sm-min') {
                    width: auto;
                }
            }
        }

        .navbar-nav {

            li {
                a,
                button {
                    padding: 12px;

                    .icon {
                        transition: transform .3s;
                    }
                }

                &.open > .dropdown__toggle > .icon {
                    transform: rotate(-180deg);
                }
            }

            &--main > li {
                border-top: 1px solid $gray-light;

                @include screen('sm-min') {
                    &:first-child {
                        border-top: none;
                    }
                }
            }

            .dropdown__menu {
                position: static;
                float: none;
                width: auto;
                margin-top: 0;
                background: transparent;
                border: 0;
                display: block;
                max-height: 0;
                overflow: hidden;
                transition: max-height .4s;

                li a,
                li button {
                    padding: 0px 10px 8px 24px;
                }

                li:last-of-type {
                    padding-bottom: 15px;
                }
            }

            .open > .dropdown__menu {
                max-height: 250px;
            }
            
            @include screen('sm-max') {
                &--sub {
                    margin-top: 10px;
                    border-top: 1px solid $gray-light;

                    li {
                        display: inline-block;
                        vertical-align: top;
                        border-bottom: none;

                        &:first-child,
                        &:last-child {
                            width: calc(50% - 50px);
                        }

                        &:last-child {
                            text-align: right;
                        }
                    }
                }
            }
        }

        .dropdown__toggle {
            &:after {
                top: 20px;
                right: 12px;
            }
        }

        [aria-expanded="true"] {
            &:after {
                transform: rotate(180deg);
            }
        }

        .navbar-toggle {
            position: absolute;
            padding: 22px 10px;
            margin-left: 50px;
            transition: margin 0.3s;
            right: 480px;
            top: 5px;

            @include screen('sm-max') {
                right: 10px;
            }

            &__title {
                position: absolute;
                right: 100%;
                top: 20px;
                font-size: rem(15px);
                color: $text-color;
                transition: color .3s;

                @media (min-width: $screen-sm) and (max-width: 820px) {
                    display: none;
                }
            }

            .icon-bar.top {
                transform: rotate(-45deg) translateY(-2px);
                transform-origin: 100% 0%;
                transition: transform 0.3s 0.1s, background 0.2s;
            }

            .icon-bar.middle {
                opacity: 0;
                margin-left: 8px;
                margin-right: -8px;
                transition: opacity 0.3s, margin 0.2s, background 0.2s;
            }

            .icon-bar.bottom {
                transform: rotate(45deg) translateY(2px);
                transform-origin: 100% 100%;
                transition: transform 0.3s 0.1s, background 0.2s;
            }

            &.collapsed {
                .icon-bar.middle {
                    opacity: 1;
                    margin-left: 0;
                    margin-right: 0;
                }

                .icon-bar.top,
                .icon-bar.bottom {
                    transform: rotate(0deg);
                }
            }

            .icon-bar + .icon-bar {
                margin-top: 5px;
            }
            .icon-bar {
                background: $brand-primary;
                width: 28px;
                display: block;
                height: 3px;
                border-radius: 3px;
            }

            &:hover {
                .navbar-toggle__title {
                    color: $link-hover-color;
                }

                .icon-bar {
                    background: $link-hover-color;
                }
            }
        }

        .navbar-lang {
            position: relative;
            text-align: right;
            opacity: 0;
            transition: opacity 0 .3s;
        }

        .in .navbar-lang {
            opacity: 1;
        }
    }

    @include screen('breakpoint-nav-min') {

        .dropdown__toggle .icon {
            left: 100%;
            left: calc(100% + 5px);
            top: 35px;
        }

        .navbar-header {
            float: left;
        }

        .navbar-toggle {
            display: none;
        }

        .navbar-collapse {
            background: #fff;
        }

        .navbar-nav {

            > li {
                display: inline-block;
                vertical-align: middle;
            }

            .dropdown:hover > .dropdown__menu {
                display: block;
            }

            &--main {
                margin-top: 112px;
                float: right;

                @include screen('lg-min') {
                    margin-left: 96px;
                    float: left;
                }

                > li {
                    padding: 0 20px;
                }
            }
        }


        .dropdown__menu {
            padding-top: 20px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                top: 10px;
                margin-left: -5px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $brand-primary;
            }

            > li > a {
                display: block;
                padding: 15px;
                clear: both;
                white-space: nowrap;
                background: $brand-primary;
                color: #fff;
                border-radius: $border-radius-base;
                transition: background .2s, color .2s;

                &:hover {
                    background: $link-hover-color;
                }
            }

            > li:not(:last-child) {
                border-bottom: 1px solid #ccb580;
            }
        }
    }

    .navbar-nav--sub {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
            font-size: rem(22px);

            &-shopping-cart {
                font-size: rem(20px);
            }

            &-leichte-sprache {
                font-size: rem(24px);
            }

            &-gebaerdensprache {
                font-size: rem(24px);
            }
        }
    }

    .circle {
        position: absolute;
        background: $brand-primary;
        color: #fff;
        right: 0;
        top: 2px;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        font-size: rem(13px);
    }


    @include screen('sm-min') {
        .navbar-nav--sub {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;

            li + li {
                border-left: 1px solid #ccb580;
            }

            li a,
            li button {
                display: block;
                background: $brand-primary;
                font-weight: bold;
                width: 50px;
                height: 70px;
                text-align: center;
                line-height: 69px;
                color: #fff;
                padding: 0;

                @include screen('md-min') {
                    width: 70px;
                }
            }

            li.active a,
            li.active button,
            li a:hover,
            li button:hover {
                background: $link-hover-color;
            }

            .circle {
                background: #000;
                right: 2px;
                top: 8px;

                @include screen('md-min') {
                    right: 8px;
                }
            }
        }

        .navbar-search {
            background: $brand-primary;
            overflow: hidden;
            text-align: right;

            .input-group {
                width: 240px;

                @include screen('md-max') {
                    width: 220px;
                }
            }

            .form-control {
                background: none;
                border: none;
                border-bottom: 1px solid #fff;
                align-self: center;
                margin-left: 20px;
                padding: 0;
                height: auto;
                color: #fff;

                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: #fff;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                  color: #fff;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                  color: #fff;
                }
                &:-moz-placeholder { /* Firefox 18- */
                  color: #fff;
                }
            }

            button {
                flex-shrink: 0;
            }
        }
    }



    &.nav-up {
        &:not(.nav-open) {
            transform: translateY(-140px);
        }

    }

    &.nav-down {
        background: #fff;
        box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.04);
    }

    &.nav-open {
        box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.04);
    }
}
