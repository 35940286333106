@charset "UTF-8";
/** 3rd party librarys */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "icons";
  src: url("../Fonts/icons.eot");
  src: url("../Fonts/icons.eot?#iefix") format("eot"), url("../Fonts/icons.woff2") format("woff2"), url("../Fonts/icons.woff") format("woff"), url("../Fonts/icons.ttf") format("truetype"), url("../Fonts/icons.svg#icons") format("svg"); }

.icon-arrow-down:before, .icon-arrow-left-long:before, .icon-arrow-left:before, .icon-arrow-right-long:before, .icon-arrow-right:before, .icon-check:before, .icon-close:before, .icon-facebook:before, .icon-gebaerdensprache:before, .icon-instagram:before, .icon-leichte-sprache:before, .icon-pause:before, .icon-play:before, .icon-search-plus:before, .icon-search:before, .icon-shopping-cart:before, .icon-trash:before, .icon-twitter:before, .icon-youtube:before, .custom-select .btn-select:after, .form-inline .checkbox label:after,
.checkbox label:after, .btn:after, .btn--back:not(.btn--sm):after, .product-teaser:after, .teaser-grid__item:not(.teaser-grid__item--transparent):after {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left-long:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right-long:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-gebaerdensprache:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-leichte-sprache:before {
  content: ""; }

.icon-pause:before {
  content: ""; }

.icon-play:before {
  content: ""; }

.icon-search-plus:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-shopping-cart:before {
  content: ""; }

.icon-trash:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

/* Custom CSS */
@font-face {
  font-family: "DIN";
  src: url("../Fonts/5591175/855cca78-9141-48c7-bc2d-5d78d489f326.eot?#iefix");
  src: url("../Fonts/5591175/855cca78-9141-48c7-bc2d-5d78d489f326.eot?#iefix") format("eot"), url("../Fonts/5591175/d6241c0e-d7b5-46c2-8d41-524b7a7c86d1.woff2") format("woff2"), url("../Fonts/5591175/b02e1860-f927-4d9a-9f4f-e66d2a5760d9.woff") format("woff"), url("../Fonts/5591175/2e5b5154-6c68-4104-b8b3-fde29e396482.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

.collapse:not(.navbar-collapse) {
  max-height: 0;
  transition: max-height .6s ease-out;
  overflow: hidden; }
  .collapse:not(.navbar-collapse).in {
    max-height: 600px; }

.panel {
  margin-bottom: 10px; }
  .panel__icon {
    width: 20px;
    height: 20px;
    fill: #e3e3e3;
    transition: fill .3s;
    margin-right: 10px; }
  .panel__arrow {
    font-size: 1.5rem;
    float: right;
    fill: #b37d1b;
    transition: transform .3s; }
  .panel__heading {
    position: relative;
    z-index: 5; }
    .panel__heading .panel__title {
      color: #1d1d1d;
      font-weight: normal;
      margin: 5px 0 0;
      font-size: 1.0625rem;
      max-width: 80%; }
      .panel__heading .panel__title.panel__title--bold {
        text-transform: none;
        font-weight: bold; }
    .panel__heading .icon,
    .panel__heading .panel__title {
      display: inline-block;
      vertical-align: middle; }
    .panel__heading button {
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;
      padding: 19px 20px;
      background: #f1f1f1;
      border-radius: 0;
      text-align: left;
      transition: background .3s; }
      .panel__heading button:hover, .panel__heading button[aria-expanded="true"] {
        background: #e3e3e3; }
        .panel__heading button:hover .panel__icon, .panel__heading button[aria-expanded="true"] .panel__icon {
          fill: #1d1d1d; }
      .panel__heading button[aria-expanded="true"] .panel__arrow {
        transform: rotate(-180deg); }
  .panel__collapse {
    background: #e3e3e3;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 20px 0 20px;
    transform: translateY(0);
    transition: margin-bottom .4s; }
    .panel__collapse p:last-child {
      margin-bottom: 30px; }

.dropdown {
  position: relative; }
  .dropdown__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 240px;
    text-align: left;
    list-style: none; }
  .dropdown__content {
    display: none; }
  .dropdown.open > .dropdown__menu,
  .dropdown.open > .dropdown__content {
    display: block; }

form .form-control {
  display: block;
  width: 100%;
  padding: 14px 16px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5;
  color: #1d1d1d;
  border: 1px solid #a7a7a7;
  background: #f8f5f0;
  border-radius: 0;
  height: 54px;
  transition: border .2s, box-shadow .2s; }
  form .form-control--narrow {
    width: 80px; }
    @media (min-width: 30em) {
      form .form-control--narrow {
        margin-left: auto;
        margin-right: auto; } }
  form .form-control:focus {
    border-color: #b37d1b; }

form input.btn {
  -webkit-appearance: none;
  border-bottom: 1px solid #b37d1b;
  border-right: 1px solid #b37d1b;
  border-color: #b37d1b;
  cursor: pointer; }

form label,
form .label {
  display: block;
  margin-bottom: 2px;
  font-size: 1rem; }

form textarea.form-control {
  height: auto; }

form .form-group {
  margin-bottom: 6px; }

form .input-group {
  display: flex; }

form .has-error .checkbox,
form .has-error .checkbox-inline,
form .has-error .control-label,
form .has-error .help-block,
form .has-error .radio,
form .has-error .radio-inline,
form .has-error.checkbox label,
form .has-error.checkbox-inline label,
form .has-error.radio label,
form .has-error.radio-inline label,
form .has-error label {
  color: #ff0000; }

form .has-error .form-control {
  border-color: #ff0000; }

form fieldset {
  margin-bottom: 25px; }

form legend:not(.label) {
  margin-bottom: 10px; }

@media (min-width: 30em) {
  form .form-inline, form.form-inline {
    width: 380px;
    max-width: 100%; }
    form .form-inline .form-group,
    form .form-inline .radio, form.form-inline .form-group,
    form.form-inline .radio {
      display: inline-block;
      vertical-align: top;
      margin-right: 20px; } }
    @media (min-width: 30em) and (max-width: 47.9375em) {
      form .form-inline .form-group:nth-child(2n),
      form .form-inline .radio:nth-child(2n), form.form-inline .form-group:nth-child(2n),
      form.form-inline .radio:nth-child(2n) {
        margin-right: 0; } }
  @media (min-width: 30em) and (max-width: 47.9375em) {
    form .form-inline .radio, form.form-inline .radio {
      width: calc(50% - 13px); } }

form .btn-tooltip {
  position: absolute;
  left: 100%;
  top: 0; }

/** Custom Select **/
.custom-select {
  position: relative;
  display: block;
  min-width: 94px; }

.custom-select select {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  opacity: 0.001; }

.custom-select select,
.custom-select .btn-select {
  padding: 14px 70px 14px 16px;
  cursor: pointer; }

.custom-select .btn-select {
  position: relative;
  display: block;
  height: 54px;
  font-size: 1rem;
  background: #fff;
  text-align: left;
  border-radius: 0;
  border: 1px solid #a7a7a7;
  background: #f8f5f0;
  color: #353535; }
  .custom-select .btn-select:after {
    content: ""; }
  .custom-select .btn-select:before {
    content: " ";
    display: block;
    background: #a7a7a7;
    width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 54px; }
  .custom-select .btn-select:after {
    color: #353535;
    display: block;
    position: absolute;
    top: 11px;
    right: 20px;
    font-size: 1.3125rem; }

.custom-select .has-error {
  border: 1px solid #ff0000; }

.btn-select.btn-focus {
  border-color: #b37d1b; }

.custom-select .label-text {
  display: inline-block;
  padding: 0; }

/** CUSTOM RADIO BUTTONS **/
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 36px; }

.form-inline .radio,
.radio {
  margin-top: 0;
  padding-left: 32px;
  position: relative;
  margin-bottom: 12px; }
  .form-inline .radio + .js-form-type-radio,
  .radio + .js-form-type-radio {
    margin-top: 15px; }
  .form-inline .radio label,
  .radio label {
    padding-left: 0;
    font-weight: normal;
    cursor: pointer;
    display: inline-block;
    font-style: normal;
    transition: color .2s; }
    .form-inline .radio label:before, .form-inline .radio label:after,
    .radio label:before,
    .radio label:after {
      position: absolute;
      content: "";
      display: inline-block; }
    .form-inline .radio label:before,
    .radio label:before {
      height: 22px;
      width: 22px;
      background: #f8f5f0;
      border: 1px solid #a7a7a7;
      border-radius: 50%;
      left: 0;
      margin-left: 0;
      top: 1px; }
    .form-inline .radio label:after,
    .radio label:after {
      background: #b37d1b;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      left: 7px;
      top: 8px;
      opacity: 0;
      transform: scale(0.6);
      transition: transform .2s, opacity .1s; }
  .form-inline .radio input[type="radio"]:focus + label:before,
  .radio input[type="radio"]:focus + label:before {
    border-color: #b37d1b; }
  .form-inline .radio input[type="radio"]:checked + label::after,
  .radio input[type="radio"]:checked + label::after {
    opacity: 1;
    transform: scale(1); }

/** CUSTOM CHECKBOXES **/
.checkbox,
.checkbox + .checkbox {
  margin-top: 0; }

.form-inline .checkbox label,
.checkbox label {
  position: relative;
  display: inline-block;
  padding: 9px 0 4px 48px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  transition: color .2s; }
  .form-inline .checkbox label a,
  .checkbox label a {
    text-decoration: underline; }
  .form-inline .checkbox label p,
  .checkbox label p {
    margin-bottom: 0; }
  .form-inline .checkbox label:after,
  .checkbox label:after {
    content: ""; }
  .form-inline .checkbox label:before,
  .checkbox label:before {
    position: absolute;
    content: "";
    display: inline-block;
    height: 26px;
    width: 26px;
    border: 1px solid #a7a7a7;
    background: #f8f5f0;
    left: 0px;
    top: 6px; }
  .form-inline .checkbox label:after,
  .checkbox label:after {
    position: absolute;
    opacity: 0;
    left: 5.5px;
    top: 8.5px;
    transform: scale(0.6);
    transition: transform .2s, opacity .1s; }
  .form-inline .checkbox label.has-error:before,
  .checkbox label.has-error:before {
    border-color: #ff0000; }

.form-inline .checkbox input[type="checkbox"]:focus + label:before,
.checkbox input[type="checkbox"]:focus + label:before {
  border-color: #cedae0;
  box-shadow: 0 0 0.4em rgba(200, 200, 200, 0.8); }

.form-inline .checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="checkbox"]:checked + label::after {
  color: #b37d1b;
  opacity: 1;
  transform: scale(1); }

body {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  color: #1d1d1d;
  background: #fff;
  transition: padding-top 0.3s;
  overflow-x: hidden;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  padding-top: 100px !important; }
  @media (min-width: 30em) {
    body {
      font-size: 1.0625rem; } }
  @media (min-width: 48em) {
    body {
      padding-top: 200px !important; } }

* {
  box-sizing: border-box; }

.sr-only, .form-inline .radio input[type="radio"],
.radio input[type="radio"], .form-inline .checkbox input[type="checkbox"],
.checkbox input[type="checkbox"], .show-on-focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0; }
  .sr-only--focusable:active, .sr-only--focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none; }

.hidden {
  display: none; }

main {
  position: relative; }
  @media (min-width: 48em) {
    main:before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      right: 0;
      top: -30px;
      background: url("../Images/bg-agnn.png") no-repeat;
      width: 738px;
      height: 574px;
      background-size: cover; } }

.container {
  max-width: 1200px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto; }
  @media (min-width: 48em) {
    .container {
      padding-left: 30px;
      padding-right: 30px; } }
  .container--breakout, .section--breakout, .section--light {
    margin-left: -15px;
    margin-right: -15px; }
    @media (min-width: 48em) {
      .container--breakout, .section--breakout, .section--light {
        margin-left: -30px;
        margin-right: -30px; } }
    @media (min-width: 75em) {
      .container--breakout, .section--breakout, .section--light {
        margin-left: calc(-50vw + 570px);
        margin-right: calc(-50vw + 570px); } }

.full-width {
  width: 100%; }

.content-block {
  display: inline-block;
  margin-bottom: 15px;
  width: 100%; }
  @media (min-width: 48em) {
    .content-block {
      margin-bottom: 30px; } }
  .content-block--md {
    margin-bottom: 20px; }
    @media (min-width: 48em) {
      .content-block--md {
        margin-bottom: 40px; } }
  .content-block--lg {
    margin-bottom: 50px; }
    @media (min-width: 48em) {
      .content-block--lg {
        margin-bottom: 100px; } }

@media (min-width: 48em) {
  .content-columns {
    columns: 2;
    column-gap: 30px;
    margin-bottom: 30px; }
    .content-columns > *:last-child {
      margin-bottom: 0; } }

.content-narrow {
  max-width: 322px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 48em) {
    .content-narrow .box {
      margin-bottom: 45px; } }

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 1.875rem;
  margin-left: -15px;
  margin-right: -15px; }
  .row--narrow {
    grid-gap: 0 0.9375rem;
    margin-left: -7.5px;
    margin-right: -7.5px; }
  .row:after {
    content: ' ';
    display: table;
    clear: both; }
  .row--center {
    align-items: center;
    justify-items: center; }
  .row--bottom {
    align-items: flex-end; }
  .row--eq-height,
  .row--eq-height > [class*="col-"] {
    display: flex;
    flex-wrap: wrap; }
  .row--lg {
    grid-gap: 0 1.875rem; }
  @media (max-width: 61.9375em) {
    .row {
      grid-gap: 0 0.9375rem; } }

.col {
  grid-column: span 12;
  width: 100%; }

[class*="col-"] {
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  grid-column: span 12; }

@media (min-width: 30em) {
  .col-xs-1 {
    grid-column: span 1; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-2 {
    grid-column: span 2; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-3 {
    grid-column: span 3; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-4 {
    grid-column: span 4; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-5 {
    grid-column: span 5; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-6 {
    grid-column: span 6; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-7 {
    grid-column: span 7; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-8 {
    grid-column: span 8; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-9 {
    grid-column: span 9; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-10 {
    grid-column: span 10; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-11 {
    grid-column: span 11; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-12 {
    grid-column: span 12; }
  .col-xs-pull {
    grid-row-start: 1; }
  .col-xs-12 {
    width: 100%; }
  .col-xs-11 {
    width: 91.66666667%; }
  .col-xs-10 {
    width: 83.33333333%; }
  .col-xs-9 {
    width: 75%; }
  .col-xs-8 {
    width: 66.66666667%; }
  .col-xs-7 {
    width: 58.33333333%; }
  .col-xs-6 {
    width: 50%; }
  .col-xs-5 {
    width: 41.66666667%; }
  .col-xs-4 {
    width: 33.33333333%; }
  .col-xs-3 {
    width: 25%; }
  .col-xs-2 {
    width: 16.66666667%; }
  .col-xs-1 {
    width: 8.33333333%; } }

@media (min-width: 48em) {
  .col-sm-1 {
    grid-column: span 1; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-2 {
    grid-column: span 2; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-3 {
    grid-column: span 3; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-4 {
    grid-column: span 4; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-5 {
    grid-column: span 5; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-6 {
    grid-column: span 6; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-7 {
    grid-column: span 7; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-8 {
    grid-column: span 8; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-9 {
    grid-column: span 9; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-10 {
    grid-column: span 10; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-11 {
    grid-column: span 11; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-12 {
    grid-column: span 12; }
  .col-sm-pull {
    grid-row-start: 1; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; } }

@media (min-width: 62em) {
  .col-md-1 {
    grid-column: span 1; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-2 {
    grid-column: span 2; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-3 {
    grid-column: span 3; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-4 {
    grid-column: span 4; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-5 {
    grid-column: span 5; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-6 {
    grid-column: span 6; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-7 {
    grid-column: span 7; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-8 {
    grid-column: span 8; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-9 {
    grid-column: span 9; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-10 {
    grid-column: span 10; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-11 {
    grid-column: span 11; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-12 {
    grid-column: span 12; }
  .col-md-pull {
    grid-row-start: 1; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; } }

@media (min-width: 75em) {
  .col-lg-1 {
    grid-column: span 1; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-2 {
    grid-column: span 2; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-3 {
    grid-column: span 3; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-4 {
    grid-column: span 4; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-5 {
    grid-column: span 5; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-6 {
    grid-column: span 6; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-7 {
    grid-column: span 7; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-8 {
    grid-column: span 8; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-9 {
    grid-column: span 9; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-10 {
    grid-column: span 10; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-11 {
    grid-column: span 11; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-12 {
    grid-column: span 12; }
  .col-lg-pull {
    grid-row-start: 1; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.33333333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; } }

@media (max-width: 29.9375em) {
  .col-xxs-6 {
    grid-column: span 6;
    width: 50%; } }

@supports (display: grid) {
  .row {
    margin-left: 0;
    margin-right: 0; }
  [class*="col-"] {
    width: auto;
    padding-left: 0;
    padding-right: 0; }
  .row--eq-height {
    display: grid; } }

@media (max-width: 29.9375em) {
  .hidden-xxs {
    display: none !important; } }

@media (max-width: 47.9375em) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 48em) and (max-width: 61.9375em) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 62em) and (max-width: 74.9375em) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 75em) {
  .hidden-lg {
    display: none !important; } }

@media (min-width: 62em) {
  .hidden-breakpoint-nav-min {
    display: none !important; } }

@media (max-width: 61.9375em) {
  .hidden-breakpoint-nav-max {
    display: none !important; } }

img {
  vertical-align: middle; }

.img-cover {
  width: 100%;
  height: auto; }

.img-responsive {
  max-width: 100%;
  height: auto;
  margin-bottom: 30px; }

figure {
  margin-bottom: 30px; }

.img-zoomable {
  display: block;
  border: 1px solid #f1f1f1;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden; }
  .img-zoomable img {
    margin-bottom: 0;
    transition: transform .4s ease-out; }
  .img-zoomable .icon {
    position: absolute;
    right: 15px;
    bottom: 12px;
    color: #fff;
    font-size: 1rem;
    transition: color .2s; }
  @media (max-width: 47.9375em) {
    .img-zoomable {
      pointer-events: none; }
      .img-zoomable .icon {
        display: none; } }
  @media (min-width: 48em) {
    .img-zoomable:hover {
      cursor: pointer; }
      .img-zoomable:hover img {
        transform: scale(1.05); }
      .img-zoomable:hover .icon {
        color: #b37d1b; } }

.gallery {
  list-style: none;
  margin-bottom: 45px;
  margin-right: -15px; }
  @media (max-width: 74.9375em) {
    .gallery {
      margin-right: -10px; } }
  .gallery li {
    display: inline-block;
    position: relative;
    margin-right: 30px; }
    @media (max-width: 74.9375em) {
      .gallery li {
        margin-right: 15px; } }
  .gallery button {
    text-decoration: none;
    color: #1d1d1d;
    text-align: left;
    font-size: 0.9375rem;
    pointer-events: initial; }
    .gallery button .icon {
      display: block; }
  .gallery img {
    max-width: 100%;
    height: auto;
    display: block;
    border: 1px solid #f1f1f1;
    transition: transform .2s; }
  .gallery li.selected button,
  .gallery li:hover button {
    color: #1d1d1d; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive--4by3 {
    padding-bottom: 75%; }
  .embed-responsive--16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive__item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

a,
button {
  cursor: pointer;
  color: #b37d1b;
  text-decoration: none;
  transition: color .2s; }
  a:hover,
  button:hover {
    color: #986a00; }

p a {
  text-decoration: underline; }

button {
  border: none;
  padding: 0;
  background: none; }

a[href^=tel] {
  cursor: default;
  color: inherit;
  text-decoration: none; }
  a[href^=tel]:hover, a[href^=tel]:focus {
    color: inherit;
    text-decoration: none; }

:focus,
a:focus,
button:focus,
:focus:active,
button:focus:active,
.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
  outline: none;
  box-shadow: none; }

[data-whatinput="keyboard"] :focus,
[data-whatinput="keyboard"] :focus:active,
[data-whatinput="keyboard"] button:focus,
[data-whatinput="keyboard"] .btn:focus {
  outline: 3px solid;
  outline: auto -webkit-focus-ring-color;
  outline-color: #5b9dd9; }

[data-whatinput="keyboard"] button.highlight {
  outline: 3px dotted;
  outline-color: #5b9dd9; }

.show-on-focus:focus {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: auto;
  height: auto;
  color: #fff;
  background: #b37d1b;
  clip: unset;
  clip-path: none;
  z-index: 1500;
  padding: 15px 30px; }

.btn {
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
  padding: 7px 20px;
  background: #b37d1b;
  line-height: 1.2;
  color: #fff;
  font-size: 1.0625rem;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  border-radius: 0;
  transition: background .2s ease-in, color .2s ease-in; }
  @media (min-width: 48em) {
    .btn {
      border-bottom: 34px solid #fff;
      border-right: 20px solid #fff; }
      .btn:after {
        content: ""; }
      .btn:after {
        color: #1d1d1d;
        font-size: 1.0625rem;
        position: absolute;
        right: -20px;
        bottom: -34px;
        transition: transform .25s ease-out; } }
    @media (min-width: 48em) and (min-width: 75em) {
      .btn:after {
        right: -30px; } }
  @media (min-width: 48em) {
      .btn.full-width {
        width: calc(100% + 20px); } }
  .active .btn, .btn:hover {
    background: #986a00;
    color: #fff; }
    .active .btn:after, .btn:hover:after {
      color: #986a00;
      transform: translateX(8px); }
  .btn--sm {
    border-bottom: none;
    border-right: none; }
    .btn--sm:after {
      content: none; }
  .btn--lg {
    padding-top: 14px;
    padding-bottom: 14px; }
  .btn--icon {
    width: 60px;
    padding-left: 0;
    padding-right: 0;
    text-align: center; }
    .btn--icon:after {
      display: none; }
  .btn--light {
    background-color: #fff;
    color: #b37d1b;
    border: 1px solid #b37d1b; }
    .btn--light:after {
      content: none; }
    .btn--light.active {
      border-color: #986a00; }
    .btn--light:hover {
      color: #fff;
      background-color: #b37d1b; }
  @media (min-width: 48em) {
    .btn--back:not(.btn--sm) {
      border: none;
      margin-bottom: 50px; }
      .btn--back:not(.btn--sm):after {
        content: ""; }
      .btn--back:not(.btn--sm):after {
        right: auto;
        left: -20px; } }
    @media (min-width: 48em) and (min-width: 75em) {
      .btn--back:not(.btn--sm):after {
        right: auto;
        left: -30px; } }
  @media (min-width: 48em) {
      .active .btn--back:not(.btn--sm):after, .btn--back:not(.btn--sm):hover:after {
        transform: translateX(-8px); } }

.btn-square {
  width: 50px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  background: #b37d1b;
  color: #fff;
  font-size: 1.125rem; }
  .btn-square:hover {
    background: #986a00;
    color: #fff; }
  .btn-square.pause .icon-play {
    display: none; }
  .btn-square.pause.play .icon-play {
    display: block; }
  .btn-square.pause.play .icon-pause {
    display: none; }

.btn-icon {
  background: #b37d1b;
  color: #fff;
  font-size: 1.3125rem;
  width: 60px; }
  .btn-icon:hover {
    background: #986a00;
    color: #fff; }

.btn-tooltip {
  display: block;
  padding: 10px;
  position: relative;
  font-size: 1.0625rem;
  text-decoration: underline; }

.btn-teardrop {
  margin-bottom: 12px;
  transition: background .3s, color .3s; }
  .btn-teardrop:hover {
    background: #986a00;
    color: #fff; }

.link-block {
  display: block;
  margin-bottom: 40px; }
  .link-block h2, .link-block h3 {
    margin-bottom: 10px; }
  .link-block p {
    color: #1d1d1d; }
  .link-block strong {
    color: #b37d1b; }

.link-inline {
  color: #b37d1b;
  text-decoration: underline; }
  .link-inline:hover {
    color: #986a00; }

@media (min-width: 48em) {
  .btn-group {
    text-align: right; }
    .btn-group > button,
    .btn-group > a {
      vertical-align: top;
      margin-right: 30px;
      border: 1px solid #b37d1b; } }

.list-inline, .list-divided, .list-social {
  list-style: none;
  margin-left: -6px;
  margin-right: -9px; }
  .list-inline > li, .list-divided > li, .list-social > li {
    display: inline-block;
    padding-left: 6px;
    padding-right: 9px; }

.list-divided > li:not(:last-child) {
  border-right: 1px solid #1d1d1d; }

.list-spread {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }
  .list-spread li {
    display: block; }
  .list-spread li {
    flex-basis: 130px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-left: 7.5px;
    margin-right: 7.5px; }
    .list-spread li img {
      max-width: 100%; }
    @media (min-width: 48em) {
      .list-spread li {
        flex-basis: 180px;
        margin-left: 15px;
        margin-right: 15px; } }

.list-box, .pagination {
  display: inline-block;
  margin: 30px -4px; }
  .list-box li, .pagination li {
    display: inline-block;
    margin: 0 4px; }
  .list-box .icon, .pagination .icon {
    font-size: 0.6875rem;
    vertical-align: middle; }
  .list-box .btn, .pagination .btn {
    min-width: auto; }
    .list-box .btn:after, .pagination .btn:after {
      content: none; }

.list-social > li {
  padding-right: 6px; }

@media (min-width: 48em) {
  .list-social {
    margin: -45px -24px 45px -10px; }
    .list-social > li {
      padding-left: 24px;
      padding-right: 24px; } }

main ul:not([class^="list"]):not(.tabs__nav):not(.gallery):not(.carousel__dots):not(.pagination) {
  list-style: none;
  padding-left: 14px;
  margin-bottom: 30px; }
  main ul:not([class^="list"]):not(.tabs__nav):not(.gallery):not(.carousel__dots):not(.pagination) li {
    text-indent: -13px; }
    main ul:not([class^="list"]):not(.tabs__nav):not(.gallery):not(.carousel__dots):not(.pagination) li:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 3px;
      height: 3px;
      margin-right: 9px;
      margin-top: -1px;
      background-color: #b37d1b;
      border-radius: 50%; }

main ol:not(.breadcrumb) {
  list-style: none;
  margin-bottom: 30px; }
  main ol:not(.breadcrumb) li {
    counter-increment: step-counter;
    margin-bottom: 10px;
    padding-left: 18px;
    position: relative; }
    main ol:not(.breadcrumb) li:before {
      content: counter(step-counter) ".";
      position: absolute;
      left: 0;
      top: 0;
      color: #b37d1b; }

.tabs {
  position: relative; }
  .tabs__nav {
    list-style: none;
    margin-bottom: 30px;
    border-bottom: 1px solid #f1f1f1; }
    .tabs__nav li {
      display: inline-block; }
      .tabs__nav li a {
        padding: 10px 20px;
        display: block; }
      .tabs__nav li.active {
        background: #f1f1f1; }
        .tabs__nav li.active a:hover {
          color: #1d1d1d; }
  .tabs__content .tab-pane:not(.active) {
    display: none; }

.teardrop, .btn-teardrop {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-bottom-right-radius: 0;
  background: #b37d1b;
  color: #fff;
  display: block;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 48em) {
    .teardrop, .btn-teardrop {
      width: 80px;
      height: 80px; } }
  @media (min-width: 62em) {
    .teardrop, .btn-teardrop {
      width: 120px;
      height: 120px; } }
  .teardrop .icon, .btn-teardrop .icon {
    font-size: 1.25rem; }
    @media (min-width: 48em) {
      .teardrop .icon, .btn-teardrop .icon {
        font-size: 1.5rem; } }
    @media (min-width: 62em) {
      .teardrop .icon, .btn-teardrop .icon {
        font-size: 1.875rem; } }
  @media (min-width: 48em) {
    .teardrop > *, .btn-teardrop > * {
      margin-bottom: 0; } }
  .teardrop--lg {
    width: 220px;
    height: 220px;
    background: #1d1d1d; }
    @media (min-width: 75em) {
      .teardrop--lg {
        width: 255px;
        height: 255px; } }
    @media (max-width: 47.9375em) {
      .teardrop--lg {
        display: block;
        height: auto;
        width: auto;
        background-color: transparent;
        text-align: left;
        color: inherit; } }

table {
  cellspacing: 10px; }
  table td, table th {
    padding: 26px 10px;
    text-align: left;
    border-bottom: 1px solid #a7a7a7;
    vertical-align: middle; }
  table thead th,
  table thead td {
    padding: 10px; }
  table tfoot th,
  table tfoot td {
    font-weight: bold;
    border-bottom: none; }
  table tbody th:last-child,
  table tbody td:last-child,
  table tfoot th:last-child,
  table tfoot td:last-child {
    white-space: nowrap; }
  table.table--articles th:last-child,
  table.table--articles td:last-child,
  table.table--articles th:nth-last-child(3),
  table.table--articles td:nth-last-child(3) {
    text-align: right; }
  table.table--articles th:nth-last-child(2),
  table.table--articles td:nth-last-child(2) {
    text-align: center; }
  table.table--articles .btn-delete {
    font-size: 1.125rem;
    margin-left: 6px;
    padding: 8px 0 8px 8px;
    display: inline-block;
    vertical-align: middle; }

.table-responsive {
  margin-bottom: 30px; }
  .table-responsive,
  .table-responsive table {
    width: 100%; }
  @media (max-width: 47.9375em) {
    .table-responsive {
      overflow-y: hidden;
      /*thead {
            display: none;
        }

        tbody td {
            border-top: none;
        }

        tbody th {
            display: block;
        }

        th, td {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include screen('xs-min') {
            tbody td {
                display: inline-block;
                width: 33%;
            }
        }

        @include screen('xs-max') {
            th, td {
                display: block;
            }
        }*/ } }

h1, .h1, h2, .h2, h3, .h3, .h2-with-subline p, h4, .h4, form legend:not(.label), table.table--articles tbody th {
  font-weight: 900;
  font-family: "DIN", "Arial", sans-serif;
  text-transform: uppercase;
  color: inherit;
  line-height: 1.1; }

h1, .h1 {
  font-size: 2.5rem;
  margin-bottom: 30px; }
  @media (min-width: 30em) {
    h1, .h1 {
      font-size: 3.125rem; } }
  @media (min-width: 48em) {
    h1, .h1 {
      font-size: 5rem;
      margin-bottom: 50px; } }
  h1--lg, .h1--lg {
    font-size: 3.125rem; }
    @media (min-width: 48em) {
      h1--lg, .h1--lg {
        font-size: 6.25rem; } }

h2, .h2 {
  font-size: 2.1875rem;
  margin-bottom: 12px; }
  @media (min-width: 30em) {
    h2, .h2 {
      font-size: 2.5rem; } }
  @media (min-width: 48em) {
    h2, .h2 {
      font-size: 3.125rem;
      margin-bottom: 30px; } }

h3, .h3, .h2-with-subline p {
  font-size: 1.5rem;
  margin-bottom: 30px; }
  @media (min-width: 48em) {
    h3, .h3, .h2-with-subline p {
      font-size: 2.5rem;
      margin-bottom: 30px; } }

h4, .h4, form legend:not(.label), table.table--articles tbody th {
  font-size: 1.5rem;
  margin-bottom: 20px; }

.h2-with-subline {
  margin-bottom: 30px; }
  .h2-with-subline h2 {
    margin-bottom: 0; }
  .h2-with-subline p {
    font-size: 1.875rem; }

p {
  margin-bottom: 30px; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

sup, sub {
  position: relative;
  vertical-align: baseline;
  top: -0.4em;
  font-size: 75%; }

sub {
  top: 0.4em; }

.txt-center {
  text-align: center; }

.txt-vcenter {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: 14px; }

.txt-right {
  text-align: right; }

.txt-danger {
  color: #ff0000; }

.txt-underline {
  text-decoration: underline; }

.txt-lg {
  font-size: 1.125rem; }
  @media (min-width: 48em) {
    .txt-lg {
      font-size: 1.25rem; } }

.txt-sm {
  font-size: 0.875rem; }

.box {
  background: #f1f1f1;
  display: block;
  color: #b37d1b;
  height: 0;
  text-align: center;
  padding-bottom: 100%;
  position: relative;
  margin-bottom: 30px; }
  .box img {
    max-width: 60%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .box--padded {
    height: auto;
    padding: 0 18px 15px;
    text-align: left;
    color: #1d1d1d; }

.breadcrumb {
  list-style: none; }
  .breadcrumb-wrapper {
    margin-bottom: 50px; }
  .breadcrumb li {
    display: inline-block; }
    .breadcrumb li,
    .breadcrumb li a {
      font-size: 0.9375rem; }
    .breadcrumb li a {
      text-decoration: underline; }
    .breadcrumb li:not(:last-child):after {
      content: '';
      margin-left: 9px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 4px solid #000; }

.carousel {
  position: relative; }
  .carousel .btn-square {
    display: inline-block;
    vertical-align: top; }
  .carousel .next .icon,
  .carousel .prev .icon {
    display: block;
    transition: transform .2s; }
    .carousel .next .icon:before,
    .carousel .prev .icon:before {
      display: block; }
  .carousel .next:hover .icon,
  .carousel .prev:hover .icon {
    transform: translateX(-3px); }
  .carousel .next:hover .icon {
    transform: translateX(3px); }
  .carousel__img {
    width: 100%;
    text-align: center; }
    .carousel__img img {
      margin-bottom: 0; }
  .carousel__controls {
    width: 290px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%; }
    @media (max-width: 29.9375em) {
      .carousel__controls {
        margin-left: 15px;
        margin-right: 15px;
        width: auto; } }
    @media (min-width: 875px) and (max-width: 1200px) {
      .carousel__controls {
        margin-left: calc(100% - 825px); } }
    @media (min-width: 75em) {
      .carousel__controls {
        position: absolute;
        bottom: 100px;
        left: calc(50vw - 577.5px); } }
  .carousel__dot {
    width: 7px;
    height: 7px;
    display: inline-block;
    margin-right: 2px;
    background: #1d1d1d; }
    .carousel__dot.active {
      background: #b37d1b; }
  .carousel blockquote {
    background: #1d1d1d;
    color: #fff;
    position: relative;
    width: 825px;
    max-width: 100%;
    padding: 36px 15px 30px;
    transition: transform .2s;
    margin-bottom: 30px;
    margin-left: auto; }
    .carousel blockquote:before {
      content: '„';
      display: block;
      position: absolute;
      top: 10px;
      left: 15px;
      line-height: 0;
      font-size: 5.875rem;
      color: #b37d1b; }
    @media (max-width: 47.9375em) {
      .carousel blockquote p:first-child {
        text-indent: 40px; } }
    @media (min-width: 48em) {
      .carousel blockquote {
        padding: 54px 50px 54px 105px; }
        .carousel blockquote:before {
          font-size: 15rem;
          top: -30px; } }
    @media (min-width: 62em) {
      .carousel blockquote {
        transform: translateX(240px); } }
    @media (min-width: 75em) {
      .carousel blockquote {
        margin-top: -35px;
        margin-right: calc(50vw - 577.5px); } }
  .carousel__item {
    overflow: hidden; }
    .carousel__item.current > blockquote {
      transform: translateX(0);
      transition: transform 1.8s ease-out; }
  .carousel--multi {
    padding: 0 0 50px;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    /*@include screen('sm-min') {
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
        }*/ }
    @media (min-width: 87.5em) {
      .carousel--multi {
        margin-left: -100px;
        margin-right: -100px; } }
    @media (min-width: 1800px) {
      .carousel--multi {
        margin-left: -290px;
        margin-right: -290px; } }
    .carousel--multi .carousel__img {
      background: none;
      margin-bottom: 15px; }
    .carousel--multi .carousel__controls {
      bottom: 0;
      left: 50%; }
      @media (max-width: 29.9375em) {
        .carousel--multi .carousel__controls {
          margin: 0; } }
      @media (min-width: 30em) {
        .carousel--multi .carousel__controls {
          position: absolute;
          width: calc(50% + 34px);
          left: auto;
          right: 10px;
          bottom: 0; } }
      @media (min-width: 48em) {
        .carousel--multi .carousel__controls {
          right: 20px;
          width: calc(50% + 24px); } }
  .carousel--gold .carousel__img {
    background: #875a12; }
  @media (max-width: 47.9375em) {
    .carousel--gold .carousel__dots {
      display: none; }
    .carousel--gold .carousel__controls {
      position: absolute;
      top: 240px;
      left: 0;
      right: 0;
      margin: 0;
      width: 100%;
      transform: translateY(-50%); }
      .carousel--gold .carousel__controls > div {
        display: flex;
        justify-content: space-between;
        width: 100%; }
    .carousel--gold blockquote {
      padding-top: 60px; }
      .carousel--gold blockquote:before {
        top: 27px; } }
  .carousel--no-controls {
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0; }
    .carousel--no-controls .carousel__controls {
      display: none; }

.cart #checkout-step-payment-method label a, .cart #checkout-step-payment-method a label {
  color: #1d1d1d; }

.cart #checkout-step-payment-method label {
  color: #b37d1b; }

.paypal-info {
  border: 1px solid #d0bc8d;
  padding: 10px;
  font-size: 16px;
  background-color: #fbfbfb;
  margin: 10px 4px 0px 0px;
  box-shadow: 0px 0px 5px #ddd; }

.btn-cookie {
  position: fixed;
  z-index: 20;
  bottom: 30px;
  left: 20px;
  display: block;
  transition: filter .2s; }
  .btn-cookie.hidden {
    display: none; }
  @media (max-width: 47.9375em) {
    .btn-cookie {
      bottom: 10px;
      left: 10px; } }
  .btn-cookie:hover {
    filter: brightness(0.95); }

footer h2 {
  font-size: 1.875rem; }

footer .footer__partner {
  text-align: center;
  padding: 40px 0;
  border-top: 1px solid #f1f1f1; }
  footer .footer__partner ul {
    justify-content: space-around;
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto; }
  footer .footer__partner img {
    filter: grayscale(100%); }
    @media (min-width: 30em) {
      footer .footer__partner img {
        max-width: 100%; } }

footer .footer__nav {
  background: #b37d1b;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0 0 18px; }
  footer .footer__nav > * {
    flex-grow: 0;
    padding-bottom: 10px;
    margin: 0; }
  footer .footer__nav p {
    margin-right: 40px; }
  footer .footer__nav ul {
    margin-left: -15px; }
  footer .footer__nav li {
    padding: 0 15px; }
  footer .footer__nav,
  footer .footer__nav a {
    font-size: 0.9375rem;
    color: #fff; }
  footer .footer__nav a {
    transition: opacity .2s; }
    footer .footer__nav a:hover {
      opacity: .8; }

header {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  background: #fff;
  transition: transform .5s, box-shadow .3s, background .3s; }
  header a,
  header button {
    font-size: 1.0625rem;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    text-decoration: none; }
    header a:hover .icon,
    header button:hover .icon {
      fill: #b37d1b; }
  header a {
    color: inherit; }
  header .active a {
    color: #b37d1b; }
  header .dropdown__toggle:after {
    content: '';
    display: block;
    position: absolute;
    right: -15px;
    top: 10px;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid transparent;
    border-top: 5px solid #1d1d1d;
    transition: transform .2s; }
  header .dropdown__toggle:hover:after {
    border-top: 5px solid #b37d1b; }
  header .navbar-header {
    display: block;
    margin-top: 64px; }
  header .navbar-nav a,
  header .navbar-nav button {
    display: block; }
  header .navbar-nav > li > a {
    position: relative; }
  @media (max-width: 61.9375em) {
    header .navbar-collapse {
      max-height: 0 !important;
      overflow: hidden;
      transition: max-height .3s; }
      header .navbar-collapse.in {
        overflow: auto;
        max-height: calc(100vh - 123px) !important;
        min-height: 290px; }
    header .navbar-header {
      margin-top: 4px; } }
  @media (max-width: 61.9375em) and (min-width: 48em) {
    header .navbar-header {
      margin: 15px 0; } }
  @media (max-width: 61.9375em) {
      header .navbar-header img {
        width: 100px; } }
    @media (max-width: 61.9375em) and (min-width: 48em) {
      header .navbar-header img {
        width: auto; } }
  @media (max-width: 61.9375em) {
    header .navbar-nav li a,
    header .navbar-nav li button {
      padding: 12px; }
      header .navbar-nav li a .icon,
      header .navbar-nav li button .icon {
        transition: transform .3s; }
    header .navbar-nav li.open > .dropdown__toggle > .icon {
      transform: rotate(-180deg); }
    header .navbar-nav--main > li {
      border-top: 1px solid #e3e3e3; } }
  @media (max-width: 61.9375em) and (min-width: 48em) {
    header .navbar-nav--main > li:first-child {
      border-top: none; } }
  @media (max-width: 61.9375em) {
    header .navbar-nav .dropdown__menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background: transparent;
      border: 0;
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: max-height .4s; }
      header .navbar-nav .dropdown__menu li a,
      header .navbar-nav .dropdown__menu li button {
        padding: 0px 10px 8px 24px; }
      header .navbar-nav .dropdown__menu li:last-of-type {
        padding-bottom: 15px; }
    header .navbar-nav .open > .dropdown__menu {
      max-height: 250px; } }

@media (max-width: 61.9375em) and (max-width: 47.9375em) {
  header .navbar-nav--sub {
    margin-top: 10px;
    border-top: 1px solid #e3e3e3; }
    header .navbar-nav--sub li {
      display: inline-block;
      vertical-align: top;
      border-bottom: none; }
      header .navbar-nav--sub li:first-child, header .navbar-nav--sub li:last-child {
        width: calc(50% - 50px); }
      header .navbar-nav--sub li:last-child {
        text-align: right; } }
  @media (max-width: 61.9375em) {
    header .dropdown__toggle:after {
      top: 20px;
      right: 12px; }
    header [aria-expanded="true"]:after {
      transform: rotate(180deg); }
    header .navbar-toggle {
      position: absolute;
      padding: 22px 10px;
      margin-left: 50px;
      transition: margin 0.3s;
      right: 480px;
      top: 5px; } }
  @media (max-width: 61.9375em) and (max-width: 47.9375em) {
    header .navbar-toggle {
      right: 10px; } }
  @media (max-width: 61.9375em) {
      header .navbar-toggle__title {
        position: absolute;
        right: 100%;
        top: 20px;
        font-size: 0.9375rem;
        color: #1d1d1d;
        transition: color .3s; } }
    @media (max-width: 61.9375em) and (min-width: 768px) and (max-width: 820px) {
      header .navbar-toggle__title {
        display: none; } }
  @media (max-width: 61.9375em) {
      header .navbar-toggle .icon-bar.top {
        transform: rotate(-45deg) translateY(-2px);
        transform-origin: 100% 0%;
        transition: transform 0.3s 0.1s, background 0.2s; }
      header .navbar-toggle .icon-bar.middle {
        opacity: 0;
        margin-left: 8px;
        margin-right: -8px;
        transition: opacity 0.3s, margin 0.2s, background 0.2s; }
      header .navbar-toggle .icon-bar.bottom {
        transform: rotate(45deg) translateY(2px);
        transform-origin: 100% 100%;
        transition: transform 0.3s 0.1s, background 0.2s; }
      header .navbar-toggle.collapsed .icon-bar.middle {
        opacity: 1;
        margin-left: 0;
        margin-right: 0; }
      header .navbar-toggle.collapsed .icon-bar.top,
      header .navbar-toggle.collapsed .icon-bar.bottom {
        transform: rotate(0deg); }
      header .navbar-toggle .icon-bar + .icon-bar {
        margin-top: 5px; }
      header .navbar-toggle .icon-bar {
        background: #b37d1b;
        width: 28px;
        display: block;
        height: 3px;
        border-radius: 3px; }
      header .navbar-toggle:hover .navbar-toggle__title {
        color: #986a00; }
      header .navbar-toggle:hover .icon-bar {
        background: #986a00; }
    header .navbar-lang {
      position: relative;
      text-align: right;
      opacity: 0;
      transition: opacity 0 .3s; }
    header .in .navbar-lang {
      opacity: 1; } }
  @media (min-width: 62em) {
    header .dropdown__toggle .icon {
      left: 100%;
      left: calc(100% + 5px);
      top: 35px; }
    header .navbar-header {
      float: left; }
    header .navbar-toggle {
      display: none; }
    header .navbar-collapse {
      background: #fff; }
    header .navbar-nav > li {
      display: inline-block;
      vertical-align: middle; }
    header .navbar-nav .dropdown:hover > .dropdown__menu {
      display: block; }
    header .navbar-nav--main {
      margin-top: 112px;
      float: right; } }
  @media (min-width: 62em) and (min-width: 75em) {
    header .navbar-nav--main {
      margin-left: 96px;
      float: left; } }
  @media (min-width: 62em) {
      header .navbar-nav--main > li {
        padding: 0 20px; }
    header .dropdown__menu {
      padding-top: 20px; }
      header .dropdown__menu:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 10px;
        margin-left: -5px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #b37d1b; }
      header .dropdown__menu > li > a {
        display: block;
        padding: 15px;
        clear: both;
        white-space: nowrap;
        background: #b37d1b;
        color: #fff;
        border-radius: 0;
        transition: background .2s, color .2s; }
        header .dropdown__menu > li > a:hover {
          background: #986a00; }
      header .dropdown__menu > li:not(:last-child) {
        border-bottom: 1px solid #ccb580; } }
  header .navbar-nav--sub {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    header .navbar-nav--sub .icon {
      font-size: 1.375rem; }
      header .navbar-nav--sub .icon-shopping-cart {
        font-size: 1.25rem; }
      header .navbar-nav--sub .icon-leichte-sprache {
        font-size: 1.5rem; }
      header .navbar-nav--sub .icon-gebaerdensprache {
        font-size: 1.5rem; }
  header .circle {
    position: absolute;
    background: #b37d1b;
    color: #fff;
    right: 0;
    top: 2px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 22px;
    font-size: 0.8125rem; }
  @media (min-width: 48em) {
    header .navbar-nav--sub {
      position: absolute;
      top: 0;
      right: 0;
      display: flex; }
      header .navbar-nav--sub li + li {
        border-left: 1px solid #ccb580; }
      header .navbar-nav--sub li a,
      header .navbar-nav--sub li button {
        display: block;
        background: #b37d1b;
        font-weight: bold;
        width: 50px;
        height: 70px;
        text-align: center;
        line-height: 69px;
        color: #fff;
        padding: 0; } }
    @media (min-width: 48em) and (min-width: 62em) {
      header .navbar-nav--sub li a,
      header .navbar-nav--sub li button {
        width: 70px; } }
  @media (min-width: 48em) {
      header .navbar-nav--sub li.active a,
      header .navbar-nav--sub li.active button,
      header .navbar-nav--sub li a:hover,
      header .navbar-nav--sub li button:hover {
        background: #986a00; }
      header .navbar-nav--sub .circle {
        background: #000;
        right: 2px;
        top: 8px; } }
    @media (min-width: 48em) and (min-width: 62em) {
      header .navbar-nav--sub .circle {
        right: 8px; } }
  @media (min-width: 48em) {
    header .navbar-search {
      background: #b37d1b;
      overflow: hidden;
      text-align: right; }
      header .navbar-search .input-group {
        width: 240px; } }
    @media (min-width: 48em) and (max-width: 61.9375em) {
      header .navbar-search .input-group {
        width: 220px; } }
  @media (min-width: 48em) {
      header .navbar-search .form-control {
        background: none;
        border: none;
        border-bottom: 1px solid #fff;
        align-self: center;
        margin-left: 20px;
        padding: 0;
        height: auto;
        color: #fff; }
        header .navbar-search .form-control::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #fff; }
        header .navbar-search .form-control::-moz-placeholder {
          /* Firefox 19+ */
          color: #fff; }
        header .navbar-search .form-control:-ms-input-placeholder {
          /* IE 10+ */
          color: #fff; }
        header .navbar-search .form-control:-moz-placeholder {
          /* Firefox 18- */
          color: #fff; }
      header .navbar-search button {
        flex-shrink: 0; } }
  header.nav-up:not(.nav-open) {
    transform: translateY(-140px); }
  header.nav-down {
    background: #fff;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.04); }
  header.nav-open {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.04); }

.pagination .btn {
  width: 55px;
  height: 50px;
  margin-bottom: 0;
  padding: 8px 10px;
  line-height: 34px; }

.pagination .icon {
  line-height: 1; }

.modal {
  position: fixed;
  visibility: hidden;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  transition: opacity .2s;
  /* The Close Button */ }
  .modal__header {
    min-height: 50px; }
    @media (min-width: 48em) {
      .modal__header {
        text-align: right;
        padding-right: 40px; } }
    @media (min-width: 62em) {
      .modal__header {
        min-height: 60px; } }
  .modal__content {
    position: relative;
    display: block;
    top: 100px;
    left: 50%;
    width: calc(100% - 30px);
    opacity: 0;
    padding: 20px 30px 30px;
    margin-bottom: 60px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    transform: translate(-50%, 80px);
    transition: transform .4s ease-out, opacity .4s ease-out; }
    @media (min-width: 62em) {
      .modal__content {
        padding: 32px 50px 50px; } }
    @media (min-width: 48em) {
      .modal__content {
        max-width: 90%; } }
    @media (min-width: 62em) {
      .modal__content {
        max-width: 1000px; } }
  .modal__controls {
    position: absolute;
    top: 50%;
    left: 30px;
    right: 30px;
    transform: translateY(-50%); }
    @media (min-width: 62em) {
      .modal__controls {
        left: 50px;
        right: 50px; } }
    @media (min-width: 75em) {
      .modal__controls {
        left: -60px;
        right: -60px; } }
  .modal img {
    border: 1px solid #f1f1f1; }
  .modal .carousel-control {
    position: absolute;
    left: -39px;
    width: 60px;
    height: 50px;
    padding: 0;
    margin-top: -6px;
    margin-left: -5px;
    font-size: 1.125rem;
    text-decoration: none;
    transition: transform .2s; }
    .modal .carousel-control:hover {
      transform: translateX(-4px); }
    .modal .carousel-control.right {
      left: auto;
      right: -46px; }
      .modal .carousel-control.right:hover {
        transform: translateX(4px); }
    @media (min-width: 62em) {
      .modal .carousel-control {
        left: -50px;
        font-size: 1.625rem; }
        .modal .carousel-control.right {
          left: auto;
          right: -52px; } }
    @media (min-width: 75em) {
      .modal .carousel-control {
        color: #fff;
        left: 0; }
        .modal .carousel-control.right {
          left: auto;
          right: 0; } }
  .modal__title {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
    min-height: 20px; }
  .modal.show {
    visibility: visible;
    opacity: 1;
    overflow: scroll; }
    .modal.show .modal__content {
      opacity: 1;
      transform: translate(-50%, 0); }
  .modal .close {
    position: absolute;
    top: 32px;
    right: 30px;
    color: #1d1d1d;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none; }
    @media (min-width: 62em) {
      .modal .close {
        right: 45px; } }
    .modal .close:hover, .modal .close:focus {
      color: #b37d1b;
      text-decoration: none;
      cursor: pointer; }

.section {
  padding-top: 20px;
  padding-bottom: 40px; }
  @media (min-width: 62em) {
    .section {
      padding-top: 50px;
      padding-bottom: 80px; } }
  .section--breakout .carousel--multi {
    max-width: calc(100% - 30px); }
    @media (min-width: 48em) {
      .section--breakout .carousel--multi {
        max-width: calc(100% - 60px); } }
    @media (min-width: 75em) {
      .section--breakout .carousel--multi {
        margin-left: auto;
        margin-right: auto;
        max-width: calc(100% - 100px); } }
    @media (min-width: 1800px) {
      .section--breakout .carousel--multi {
        margin-left: auto;
        margin-right: auto;
        max-width: 1800px; } }
  .section--light {
    background: #f1f1f1; }
  .section--light.section--breakout {
    padding-top: 50px;
    padding-bottom: 70px; }
    @media (min-width: 62em) {
      .section--light.section--breakout {
        padding-top: 140px;
        padding-bottom: 120px; } }
  .section:first-child {
    padding-top: 0; }

.product-teaser {
  display: block;
  margin-bottom: 30px;
  padding: 15px 15px 22px;
  background-color: #fff;
  border: 1px solid #b37d1b;
  outline: 3px solid #fff;
  color: inherit;
  overflow: hidden;
  transition: outline .15s, color .3s; }
  .product-teaser:after {
    content: ""; }
  .product-teaser:after {
    color: #1d1d1d;
    font-size: 1.0625rem;
    position: absolute;
    right: 15px;
    bottom: 52px;
    transform: translateX(-5px);
    transition: transform .25s ease-out, color .25s; }
  @media (min-width: 48em) {
    .product-teaser {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column; } }
  .product-teaser .h4, .product-teaser form legend:not(.label), form .product-teaser legend:not(.label), .product-teaser table.table--articles tbody th, table.table--articles tbody .product-teaser th {
    margin-bottom: 6px; }
  .product-teaser .txt-sm {
    margin-right: 30px; }
  .product-teaser__img {
    width: 100%;
    margin-bottom: 20px; }
  .product-teaser img {
    margin: 0 auto;
    display: block; }
  @media (min-width: 30em) {
    .product-teaser {
      /*&__img {
            height: 0;
            padding-bottom: 107%;
            overflow: hidden;
            position: relative;
        }*/ }
      .product-teaser img {
        /*position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            align-self: center;*/ } }
  .row--eq-height .product-teaser {
    width: 100%; }
  .product-teaser:hover {
    color: #1d1d1d;
    outline-color: #b37d1b; }
    .product-teaser:hover .product-teaser__title,
    .product-teaser:hover .icon {
      color: #b37d1b; }
    .product-teaser:hover:after {
      color: #b37d1b;
      transform: translateX(0); }

.teaser {
  display: block;
  margin-bottom: 30px;
  padding-bottom: 30px;
  color: inherit; }
  .teaser__img-wrapper,
  .teaser p {
    margin-bottom: 15px; }
  .teaser img {
    transition: transform .4s;
    transform: scale(1.1);
    margin-bottom: 0; }
  .teaser h3 {
    margin-bottom: 20px; }
  .teaser span {
    color: #b37d1b;
    text-decoration: underline; }
  .teaser:hover img {
    transform: scale(1); }
  .teaser__img-wrapper {
    overflow: hidden; }

.teaser-grid {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 35px; }
  .teaser-grid p {
    margin-bottom: 10px; }
  .teaser-grid img {
    margin-bottom: 0;
    width: 100%;
    transition: opacity .3s; }
  @media (min-width: 48em) {
    .teaser-grid {
      margin-bottom: 80px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 0.9375rem;
      margin-left: 30px;
      margin-right: 30px; }
      .teaser-grid h2 {
        margin-bottom: 0; } }
    @media (min-width: 48em) and (max-width: 87.4375em) {
      .teaser-grid h2 {
        font-size: 2.625rem; } }
    @media (min-width: 48em) and (max-width: 74.9375em) {
      .teaser-grid h2 {
        font-size: 2.25rem; } }
    @media (min-width: 48em) and (max-width: 61.9375em) {
      .teaser-grid h2 {
        font-size: 1.625rem; } }
  @media (min-width: 48em) and (max-width: 61.9375em) {
    .teaser-grid p {
      font-size: 0.9375rem; } }
  @media (min-width: 48em) {
      .teaser-grid .h4, .teaser-grid form legend:not(.label), form .teaser-grid legend:not(.label), .teaser-grid table.table--articles tbody th, table.table--articles tbody .teaser-grid th {
        transform: translateY(70px);
        transition: transform .4s; } }
    @media (min-width: 48em) and (max-width: 61.9375em) {
      .teaser-grid .h4, .teaser-grid form legend:not(.label), form .teaser-grid legend:not(.label), .teaser-grid table.table--articles tbody th, table.table--articles tbody .teaser-grid th {
        font-size: 1.25rem; } }
  @media (min-width: 48em) {
      .teaser-grid__hover {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        color: #fff;
        transform: translateY(100%);
        transition: transform .35s; } }
  @media (min-width: 75em) {
    .teaser-grid {
      grid-gap: 1.875rem;
      margin-bottom: 100px; } }
  @media (max-width: 29.9375em) {
    .teaser-grid img {
      margin: 20px 20px 0;
      width: calc(100% - 40px);
      max-width: 250px; } }
  @media (max-width: 47.9375em) {
    .teaser-grid {
      display: flex;
      flex-wrap: wrap; }
      .teaser-grid__item {
        margin-bottom: 15px;
        padding-bottom: 20px;
        width: 100%; }
        .teaser-grid__item:not(.teaser-grid__item--narrow) {
          background: #e3e3e3;
          color: #1d1d1d; } }
      @media (max-width: 47.9375em) and (min-width: 30em) {
        .teaser-grid__item:not(.teaser-grid__item--narrow) {
          width: calc(50% - 7.5px);
          display: inline-block; }
          .teaser-grid__item:not(.teaser-grid__item--narrow):nth-child(2n) {
            margin-right: 15px; } }
  @media (max-width: 47.9375em) {
        .teaser-grid__item.teaser-grid__item--narrow {
          width: 100%; } }
  .teaser-grid__hover {
    padding: 15px; }
    @media (min-width: 75em) {
      .teaser-grid__hover {
        padding: 40px; } }
  .teaser-grid__item {
    display: block;
    grid-column: span 2;
    overflow: hidden;
    position: relative;
    background: #b37d1b; }
    .teaser-grid__item--narrow {
      grid-column: span 1; }
    .teaser-grid__item--transparent {
      background: none;
      color: #1d1d1d;
      display: flex;
      align-items: center; }
    .teaser-grid__item:not(.teaser-grid__item--transparent):after {
      content: ""; }
    .teaser-grid__item:not(.teaser-grid__item--transparent):after {
      font-size: 1.0625rem;
      position: absolute;
      right: 24px;
      bottom: 12px;
      transition: transform .15s, opacity .15s; }
      @media (min-width: 48em) {
        .teaser-grid__item:not(.teaser-grid__item--transparent):after {
          color: #fff;
          opacity: 0;
          transform: translateX(-30px); } }
      @media (min-width: 75em) {
        .teaser-grid__item:not(.teaser-grid__item--transparent):after {
          bottom: 22px; } }
    .teaser-grid__item:not(.teaser-grid__item--transparent):not(.teaser-grid__item--narrow):after {
      color: #b37d1b; }
    @media (min-width: 48em) {
      .teaser-grid__item:hover .teaser-grid__hover, .teaser-grid__item:focus .teaser-grid__hover {
        transform: translateY(0); }
      .teaser-grid__item:hover img, .teaser-grid__item:focus img {
        opacity: 0; }
      .teaser-grid__item:hover:after, .teaser-grid__item:focus:after {
        opacity: 1;
        transform: translateX(0);
        transition: transform .25s .2s, opacity .25s .2s; }
      .teaser-grid__item:hover .h4, .teaser-grid__item:hover form legend:not(.label), form .teaser-grid__item:hover legend:not(.label), .teaser-grid__item:hover table.table--articles tbody th, table.table--articles tbody .teaser-grid__item:hover th, .teaser-grid__item:focus .h4, .teaser-grid__item:focus form legend:not(.label), form .teaser-grid__item:focus legend:not(.label), .teaser-grid__item:focus table.table--articles tbody th, table.table--articles tbody .teaser-grid__item:focus th {
        transform: translateY(0); } }
    .teaser-grid__item--primary {
      padding: 15px 15px 50px;
      color: #fff;
      display: flex;
      align-items: flex-end;
      transition: background .3s; }
      @media (min-width: 75em) {
        .teaser-grid__item--primary {
          padding: 30px 30px 70px; } }
      .teaser-grid__item--primary:not(.teaser-grid__item--transparent):after {
        opacity: 1; }
        @media (min-width: 48em) {
          .teaser-grid__item--primary:not(.teaser-grid__item--transparent):after {
            transform: translateX(-12px); } }
      .teaser-grid__item--primary:hover {
        background: #986a00;
        color: #fff; }
        .teaser-grid__item--primary:hover:not(.teaser-grid__item--transparent):after {
          transition: transform .2s, opacity .2s;
          transform: translateX(0); }
