.tabs {
    position: relative;

    &__nav {
        list-style: none;
        margin-bottom: $grid-gutter-width;
        border-bottom: 1px solid $gray-lighter;

        li {
            display: inline-block;

            a {
                padding: 10px 20px;
                display: block;
            }

            &.active {
                background: $gray-lighter;

                a:hover {
                    color: $text-color;
                }
            }
        }
    }

    &__content {
        .tab-pane {
            &:not(.active) {
                display: none;
            }
        }
    }
}
