// Colors
$gray-darker:           #1d1d1d;
$gray-dark:             #353535;
$gray:                  #b7b7b7;
$gray-light:            #e3e3e3;
$gray-lighter:          #f1f1f1;

$form-bg:              #f8f5f0;
$form-border:          #a7a7a7;

$gray-border:          $gray-lighter;

$brand-primary:         #b37d1b;
$brand-primary-dark:    #986a00;
$brand-primary-darker:  #875a12;

$state-danger:          #ff0000;

$text-color:            $gray-darker;

$link-color:            $brand-primary;
$link-hover-color:      $brand-primary-dark;
$link-hover-decoration: none;


// Fonts
@font-face{
    font-family:"DIN";
    src:    url("../Fonts/5591175/855cca78-9141-48c7-bc2d-5d78d489f326.eot?#iefix");
    src:    url("../Fonts/5591175/855cca78-9141-48c7-bc2d-5d78d489f326.eot?#iefix") format("eot"),
            url("../Fonts/5591175/d6241c0e-d7b5-46c2-8d41-524b7a7c86d1.woff2") format("woff2"),
            url("../Fonts/5591175/b02e1860-f927-4d9a-9f4f-e66d2a5760d9.woff") format("woff"),
            url("../Fonts/5591175/2e5b5154-6c68-4104-b8b3-fde29e396482.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}


$font-family-sans-serif:  Arial, Helvetica, sans-serif;

$font-size-base:        rem(17px);
$font-weight-base:      normal;

$font-size-h1:          rem(80px);
$font-size-h2:          rem(50px);
$font-size-h3:          rem(40px);
$font-size-h4:          rem(24px);
$font-size-h5:          rem(16px);

$line-height-base:        1.5;

$headings-font-family:    'DIN', 'Arial', sans-serif;
$headings-font-weight:    900;
$headings-line-height:    1.1;
$headings-color:          inherit;


//== Components
$border-radius-base:        0;


// Breakpoints
$screen-xs:             480px;
$screen-sm:             768px;
$screen-md:             992px;
$screen-lg:             1200px;
$screen-xl:             1400px;

$breakpoint-nav:        $screen-md;

// Grid Gutter
$grid-gutter-width:     30px;
$grid-gutter-width--sm: 15px;
