.btn-cookie {
    position: fixed;
    z-index: 20;
    bottom: 30px;
    left: 20px;
    display: block;
    transition: filter .2s;

    &.hidden {
        display: none;
    }

    @include screen('sm-max') {
        bottom: 10px;
        left: 10px;
    }

    &:hover {
        filter: brightness(.95);
    }
}
