.section {
    padding-top: 20px;
    padding-bottom: 40px;

    @include screen('md-min') {
        padding-top: 50px;
        padding-bottom: 80px;
    }

    &--breakout {
        @extend .container--breakout;

        .carousel--multi {
            max-width: calc(100% - 30px);

            @include screen('sm-min') {
                max-width: calc(100% - 60px);
            }

            @include screen('lg-min') {
                margin-left: auto;
                margin-right: auto;
                max-width: calc(100% - 100px);
            }
    
            @media (min-width: 1800px) {
                margin-left: auto;
                margin-right: auto;
                max-width: 1800px;
            }
        }
    }

    &--light {
        @extend .container--breakout;

        background: $gray-lighter;
    }

    &--light.section--breakout {
        padding-top: 50px;
        padding-bottom: 70px;

        @include screen('md-min') {
            padding-top: 140px;
            padding-bottom: 120px;
        }
    }

    &:first-child {
        padding-top: 0;
    }
}
