@font-face {
	font-family: "icons";
	src: url('../Fonts/icons.eot');
	src: url('../Fonts/icons.eot?#iefix') format('eot'),
		url('../Fonts/icons.woff2') format('woff2'),
		url('../Fonts/icons.woff') format('woff'),
		url('../Fonts/icons.ttf') format('truetype'),
		url('../Fonts/icons.svg#icons') format('svg');
}

@mixin icon-styles {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == arrow-down {
		$char: "\E600";
	}
	@if $filename == arrow-left-long {
		$char: "\E601";
	}
	@if $filename == arrow-left {
		$char: "\E602";
	}
	@if $filename == arrow-right-long {
		$char: "\E603";
	}
	@if $filename == arrow-right {
		$char: "\E604";
	}
	@if $filename == check {
		$char: "\E605";
	}
	@if $filename == close {
		$char: "\E606";
	}
	@if $filename == facebook {
		$char: "\E607";
	}
	@if $filename == gebaerdensprache {
		$char: "\E608";
	}
	@if $filename == instagram {
		$char: "\E609";
	}
	@if $filename == leichte-sprache {
		$char: "\E60A";
	}
	@if $filename == pause {
		$char: "\E60B";
	}
	@if $filename == play {
		$char: "\E60C";
	}
	@if $filename == search-plus {
		$char: "\E60D";
	}
	@if $filename == search {
		$char: "\E60E";
	}
	@if $filename == shopping-cart {
		$char: "\E60F";
	}
	@if $filename == trash {
		$char: "\E610";
	}
	@if $filename == twitter {
		$char: "\E611";
	}
	@if $filename == youtube {
		$char: "\E612";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-arrow-down {
	@include icon(arrow-down);
}
.icon-arrow-left-long {
	@include icon(arrow-left-long);
}
.icon-arrow-left {
	@include icon(arrow-left);
}
.icon-arrow-right-long {
	@include icon(arrow-right-long);
}
.icon-arrow-right {
	@include icon(arrow-right);
}
.icon-check {
	@include icon(check);
}
.icon-close {
	@include icon(close);
}
.icon-facebook {
	@include icon(facebook);
}
.icon-gebaerdensprache {
	@include icon(gebaerdensprache);
}
.icon-instagram {
	@include icon(instagram);
}
.icon-leichte-sprache {
	@include icon(leichte-sprache);
}
.icon-pause {
	@include icon(pause);
}
.icon-play {
	@include icon(play);
}
.icon-search-plus {
	@include icon(search-plus);
}
.icon-search {
	@include icon(search);
}
.icon-shopping-cart {
	@include icon(shopping-cart);
}
.icon-trash {
	@include icon(trash);
}
.icon-twitter {
	@include icon(twitter);
}
.icon-youtube {
	@include icon(youtube);
}
