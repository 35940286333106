.teaser {
    display: block;
    margin-bottom: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    color: inherit;

    &__img-wrapper,
    p {
        margin-bottom: 15px;
    }

    img {
        transition: transform .4s;
        transform: scale(1.1);
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: 20px;
    }

    span {
        color: $link-color;
        text-decoration: underline;
    }

    &:hover {
        img {
            transform: scale(1);
        }
    }

    &__img-wrapper {
        overflow: hidden;
    }
}

.teaser-grid {
        margin-left: $grid-gutter-width--sm;
        margin-right: $grid-gutter-width--sm;
        margin-bottom: 35px;

        p {
            margin-bottom: 10px;
        }

        img {
            margin-bottom: 0;
            width: 100%;
            transition: opacity .3s;
        }

        @include screen('sm-min') {
            margin-bottom: 80px;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: rem($grid-gutter-width--sm);
            margin-left: $grid-gutter-width;
            margin-right: $grid-gutter-width;

            h2 {
                margin-bottom: 0;

                @include screen('xl-max') {
                    font-size: rem(42px);
                }

                @include screen('lg-max') {
                    font-size: rem(36px);
                }

                @include screen('md-max') {
                    font-size: rem(26px);
                }
            }

            p {
                @include screen('md-max') {
                    font-size: rem(15px);
                }
            }

            .h4 {
                transform: translateY(70px);
                transition: transform .4s;

                @include screen('md-max') {
                    font-size: rem(20px);
                }
            }

            &__hover {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 15px;
                color: #fff;
                transform: translateY(100%);
                transition: transform .35s;
            }
        }

        @include screen('lg-min') {
            grid-gap: rem($grid-gutter-width);
            margin-bottom: 100px;
        }

        @include screen('xs-max') {
            img {
                margin: 20px 20px 0;
                width: calc(100% - 40px);
                max-width: 250px;
            }
        }

        @include screen('sm-max') {
            display: flex;
            flex-wrap: wrap;

            &__item {
                margin-bottom: 15px;
                padding-bottom: 20px;
                width: 100%;
                
                &:not(.teaser-grid__item--narrow) {
                    background: $gray-light;
                    color: $text-color;

                    @include screen('xs-min') {
                        width: calc(50% - 7.5px);
                        display: inline-block;
                        
                        &:nth-child(2n) {
                            margin-right: 15px;
                        }
                    }
                }

                &.teaser-grid__item--narrow {
                    width: 100%;
                }
            }
        }

        &__hover {
            padding: 15px;

            @include screen('lg-min') {
                padding: 40px;
            }
        }

        &__item {
            display: block;
            grid-column: span 2;
            overflow: hidden;
            position: relative;
            background: $brand-primary;

            &--narrow {
                grid-column: span 1;
            }

            &--transparent {
                background: none;
                color: $text-color;
                display: flex;
                align-items: center;
            }

            &:not(.teaser-grid__item--transparent) {
                @include icon(arrow-right-long, after);

                &:after {
                    font-size: rem(17px);
                    position: absolute;
                    right: 24px;
                    bottom: 12px;
                    transition: transform .15s, opacity .15s;

                    @include screen('sm-min') {
                        color: #fff;
                        opacity: 0;
                        transform: translateX(-30px);
                    }

                    @include screen('lg-min') {
                        bottom: 22px;
                    }
                }

                &:not(.teaser-grid__item--narrow):after {
                    color: $brand-primary;
                }
            }

            &:hover,
            &:focus {
                @include screen('sm-min') {
                    .teaser-grid__hover {
                        transform: translateY(0);
                    }

                    img {
                        opacity: 0;
                    }

                    &:after {
                        opacity: 1;
                        transform: translateX(0);
                        transition: transform .25s .2s, opacity .25s .2s;
                    }
                    .h4 {
                        transform: translateY(0);
                    }
                }
            }


            &--primary {
                padding: 15px 15px 50px;
                color: #fff;
                display: flex;
                align-items: flex-end;
                transition: background .3s;

                @include screen('lg-min') {
                    padding: 30px 30px 70px;
                }

                &:not(.teaser-grid__item--transparent):after {
                    opacity: 1;

                    @include screen('sm-min') {
                        transform: translateX(-12px);
                    }
                }

                &:hover {
                    background: $link-hover-color;
                    color: #fff;

                    &:not(.teaser-grid__item--transparent):after {
                        transition: transform .2s, opacity .2s;
                        transform: translateX(0);
                    }
                }
            }
    }
    
}



