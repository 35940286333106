.carousel {
    position: relative;

    .btn-square {
        display: inline-block;
        vertical-align: top;
    }

    .next,
    .prev {
        .icon {
            display: block;
            transition: transform .2s;

            &:before {
                display: block;
            }
        }

        &:hover {
            .icon {
                transform: translateX(-3px);
            }
        }
    }

    .next {
        &:hover {
            .icon {
                transform: translateX(3px);
            }
        }
    }

    &__img {
        //background: #fff;
        width: 100%;
        text-align: center;

        img {
            margin-bottom: 0;
        }
    }

    &__controls {
        width: 290px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;

        @include screen('xs-max') {
            margin-left: $grid-gutter-width--sm;
            margin-right: $grid-gutter-width--sm;
            width: auto;
        }

        @media (min-width: 875px) and (max-width: $screen-lg) {
            margin-left: calc(100% - 825px);
        }

        @include screen('lg-min') {
            position: absolute;
            bottom: 100px;
            left: calc(50vw - 577.5px);
        }
    }

    &__dot {
        width: 7px;
        height: 7px;
        display: inline-block;
        margin-right: 2px;
        background: $text-color;

        &.active {
            background: $brand-primary;
        }
    }

    

    blockquote {
        background: $gray-darker;
        color: #fff;
        position: relative;
        width: 825px;
        max-width: 100%;
        padding: 36px 15px 30px;
        transition: transform .2s;
        margin-bottom: 30px;
        margin-left: auto;

    
        &:before {
            content: '„';
            display: block;
            position: absolute;
            top: 10px;
            left: 15px;
            line-height: 0;
            font-size: rem(94px);
            color: $brand-primary;
        }

        @include screen('sm-max') {
            p:first-child {
                text-indent: 40px;
            }
        }

        @include screen('sm-min') {
            padding: 54px 50px 54px 105px;

            &:before {
                font-size: rem(240px);
                top: -30px;
            }
        }

        @include screen('md-min') {
            transform: translateX(240px);
        }

        @include screen('lg-min') {
            margin-top: -35px;
            margin-right: calc(50vw - 577.5px);
        }
    }

    &__item {
        overflow: hidden;

        &.current > blockquote {
            transform: translateX(0);
            transition: transform 1.8s ease-out;
        }
    }
    

    &--multi {
        padding: 0 0 50px;
        max-width: 1800px;
        margin-left: auto;
        margin-right: auto;

        /*@include screen('sm-min') {
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
        }*/

        @include screen('xl-min') {
            margin-left: -100px;
            margin-right: -100px;
        }

        @media (min-width: 1800px) {
            margin-left: -290px;
            margin-right: -290px;
        }

        .carousel__img {
            background: none;
            margin-bottom: $grid-gutter-width--sm;
        }

        .carousel__controls {
            bottom: 0;
            left: 50%;

            @include screen('xs-max') {
                margin: 0;
            }
            
            @include screen('xs-min') {
                position: absolute;
                width: calc(50% + 34px);
                left: auto;
                right: 10px;
                bottom: 0;
            }

            @include screen('sm-min') {
                right: 20px;
                width: calc(50% + 24px);
            }
        }
    }

    &--gold {
        .carousel__img {
            background: $brand-primary-darker;
        }

        @include screen('sm-max') {
            .carousel__dots {
                display: none;
            }

            .carousel__controls {
                position: absolute;
                top: 240px;
                left: 0;
                right: 0;
                margin: 0;
                width: 100%;
                transform: translateY(-50%);

                & > div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }
            }

            blockquote {
                padding-top: 60px;

                &:before {
                    top: 27px;
                }
            }
        }
    }

    &--no-controls {
        padding-bottom: 0;
        margin-left: 0;
        margin-right: 0;

        .carousel__controls {
            display: none;
        }
    }
}
