.breadcrumb {
    list-style: none;

    &-wrapper {
        margin-bottom: 50px;
    }

    li {
        display: inline-block;

        &,
        a {
            font-size: rem(15px);
        }

        a {
            text-decoration: underline;
        }
    
        &:not(:last-child) {
            
            &:after {
                content: '';
                margin-left: 9px;
                margin-right: 5px;
                display: inline-block;
                vertical-align: middle;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 4px solid #000;
            }
        }
    }
}