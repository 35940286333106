.product-teaser {
    display: block;
    margin-bottom: $grid-gutter-width;
    padding: 15px 15px 22px;
    background-color: #fff;
    border: 1px solid $brand-primary;
    outline: 3px solid #fff;
    color: inherit;
    overflow: hidden;
    transition: outline .15s, color .3s;

    @include icon(arrow-right, after);

    &:after {
        color: $text-color;
        font-size: rem(17px);
        position: absolute;
        right: 15px;
        bottom: 52px;
        transform: translateX(-5px);
        transition: transform .25s ease-out, color .25s;
    }

    @include screen('sm-min') {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;   
        flex-direction: column;
    }

    .h4 {
        margin-bottom: 6px;
    }

    .txt-sm {
        margin-right: 30px;
    }

    &__img {
        width: 100%;
        margin-bottom: 20px;
    }

    img {
        //max-width: 208px;
        margin: 0 auto;
        display: block;
    }

    @include screen('xs-min') {
        /*&__img {
            height: 0;
            padding-bottom: 107%;
            overflow: hidden;
            position: relative;
        }*/

        img {
            /*position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            align-self: center;*/
        }
    }

    .row--eq-height & {
        width: 100%;
    }

    &:hover {
        color: $text-color;
        outline-color: $brand-primary;

        .product-teaser__title,
        .icon {
            color: $brand-primary;
        }

        &:after {
            color: $brand-primary;
            transform: translateX(0);
        }
    }
}
