table {
    cellspacing: 10px;
    
    td, th {
        padding: 26px 10px;
        text-align: left;
        border-bottom: 1px solid $form-border;
        vertical-align: middle;
    }

    thead th, 
    thead td {
        padding: 10px;
    }

    tfoot th, 
    tfoot td {
        font-weight: bold;
        border-bottom: none;
    }

    tbody th:last-child,
    tbody td:last-child,
    tfoot th:last-child,
    tfoot td:last-child {
        white-space: nowrap;
    }

    &.table--articles {
        tbody th {
            @extend .h4;
        }

        th:last-child,
        td:last-child,
        th:nth-last-child(3),
        td:nth-last-child(3) {
            text-align: right;
        }

        th:nth-last-child(2),
        td:nth-last-child(2) {
            text-align: center;
        }

        .btn-delete {
            font-size: rem(18px);
            margin-left: 6px;
            padding: 8px 0 8px 8px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.table-responsive {
    margin-bottom: 30px;

    &,
    table {
        width: 100%;
    }

    @include screen('sm-max') {

        overflow-y: hidden;

        /*thead {
            display: none;
        }

        tbody td {
            border-top: none;
        }

        tbody th {
            display: block;
        }

        th, td {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include screen('xs-min') {
            tbody td {
                display: inline-block;
                width: 33%;
            }
        }

        @include screen('xs-max') {
            th, td {
                display: block;
            }
        }*/
    }
}