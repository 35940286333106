.row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 rem($grid-gutter-width);
    margin-left: (-1 * $grid-gutter-width / 2);
    margin-right: (-1 * $grid-gutter-width / 2);

    &--narrow {
        grid-gap: 0 rem($grid-gutter-width--sm);
        margin-left: (-1 * $grid-gutter-width--sm / 2);
        margin-right: (-1 * $grid-gutter-width--sm / 2);
    }

    &:after {
        content: ' ';
        display: table;
        clear: both;
    }

    &--center {
        align-items: center;
        justify-items: center;
    }

    &--bottom {
        align-items: flex-end;
    }

    &--eq-height,
    &--eq-height > [class*="col-"] {
        display: flex;
        flex-wrap: wrap;
    }

    &--lg {
        grid-gap: 0 rem($grid-gutter-width);
    }

    @include screen('md-max') {
        grid-gap: 0 rem($grid-gutter-width--sm);
    }
}

.col {
    grid-column: span 12;
    width: 100%;
}

[class*="col-"] {
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
    grid-column: span 12;
}

$sizes: 'xs', 'sm', 'md', 'lg';
@each $size in $sizes {
    @include screen('#{$size}-min') {
        @for $i from 1 through 12 {
            .col-#{$size}-#{$i} {
                grid-column: span $i;
            }
            
            .col-#{$size}-pull {
                grid-row-start: 1;
            }
        }

        .col-#{$size}-12 {
          width: 100%;
        }
        .col-#{$size}-11 {
          width: 91.66666667%;
        }
        .col-#{$size}-10 {
          width: 83.33333333%;
        }
        .col-#{$size}-9 {
          width: 75%;
        }
        .col-#{$size}-8 {
          width: 66.66666667%;
        }
        .col-#{$size}-7 {
          width: 58.33333333%;
        }
        .col-#{$size}-6 {
          width: 50%;
        }
        .col-#{$size}-5 {
          width: 41.66666667%;
        }
        .col-#{$size}-4 {
          width: 33.33333333%;
        }
        .col-#{$size}-3 {
          width: 25%;
        }
        .col-#{$size}-2 {
          width: 16.66666667%;
        }
        .col-#{$size}-1 {
          width: 8.33333333%;
        }
    }
}

.col-xxs-6 {
    @include screen('xs-max') {
        grid-column: span 6;
        width: 50%;
    }
}

@supports (display: grid) {
    .row {
        margin-left: 0;
        margin-right: 0;
    }

    [class*="col-"] {
        width: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .row--eq-height {
        display: grid
    }
}

@include screen('xs-max') {
  .hidden-xxs {
    display: none !important;
  }
}
@include screen('sm-max') {
  .hidden-xs {
    display: none !important;
  }
}
@include screen('sm') {
  .hidden-sm {
    display: none !important;
  }
}
@include screen('md') {
  .hidden-md {
    display: none !important;
  }
}
@include screen('lg-min') {
  .hidden-lg {
    display: none !important;
  }
}
@include screen('breakpoint-nav-min') {
  .hidden-breakpoint-nav-min {
    display: none !important;
  }
}
@include screen('breakpoint-nav-max') {
  .hidden-breakpoint-nav-max {
    display: none !important;
  }
}
