.dropdown {
    position: relative;

    &__menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 240px;
        text-align: left;
        list-style: none;
    }

    &__content {
        display: none;
    }

    &.open > .dropdown__menu,
    &.open > .dropdown__content {
        display: block;
    }
}
