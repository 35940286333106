footer {
    h2 {
        font-size: rem(30px);
    }

    .footer__partner {
        text-align: center;
        //background: $gray-lighter;
        padding: 40px 0;
        border-top: 1px solid $gray-lighter;

        ul {
            justify-content: space-around;
            max-width: 1050px;
            margin-left: auto;
            margin-right: auto;

        }

        img {
            filter: grayscale(100%);

            @include screen('xs-min') {
                max-width: 100%;
            }
        }
    }

    .footer__nav {
        background: $brand-primary;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px 0 0 18px;

        & > * {
            flex-grow: 0;
            padding-bottom: 10px;
            margin: 0;
        }

        p {
            margin-right: 40px;
        }

        ul {
            margin-left: -15px;
        }

        li {
            padding: 0 15px;
        }

        &,
        a {
            font-size: rem(15px);
            color: #fff;
        }

        a {
            transition: opacity .2s;

            &:hover {
                opacity: .8;
            }
        }
    }
}
