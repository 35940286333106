html {
    // font-size: 62.5%; // 10px -> (10 / 16)
}

body {
    //font-size: 1.6rem;

    font-family: $font-family-sans-serif;
    font-weight: $font-weight-base;
    font-size: rem(16px);
    line-height: $line-height-base;
    color: $text-color;
    background: #fff;
    transition: padding-top 0.3s;
    overflow-x: hidden;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;

    padding-top: 100px !important;

    @include screen("xs-min") {
        font-size: $font-size-base;
    }

    @include screen("sm-min") {
        padding-top: 200px !important;
    }
}

* {
    box-sizing: border-box;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;

    &--focusable:active,
    &--focusable:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
        -webkit-clip-path: none;
        clip-path: none;
    }
}

.hidden {
    display: none;
}

main {
    position: relative;

    @include screen("sm-min") {
        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            right: 0;
            top: -30px;
            background: url("../Images/bg-agnn.png") no-repeat;
            width: 738px;
            height: 574px;
            background-size: cover;
        }
    }
}

.container {
    max-width: $screen-lg;
    width: 100%;
    padding-left: $grid-gutter-width--sm;
    padding-right: $grid-gutter-width--sm;
    margin: 0 auto;

    @include screen("sm-min") {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }

    &--breakout {
        margin-left: (-1 * $grid-gutter-width--sm);
        margin-right: (-1 * $grid-gutter-width--sm);

        @include screen("sm-min") {
            margin-left: (-1 * $grid-gutter-width);
            margin-right: (-1 * $grid-gutter-width);
        }

        @include screen("lg-min") {
            margin-left: calc(-50vw + 570px);
            margin-right: calc(-50vw + 570px);
        }
    }
}

.full-width {
    width: 100%;
}

.content-block {
    display: inline-block;
    margin-bottom: $grid-gutter-width--sm;
    width: 100%;

    @include screen("sm-min") {
        margin-bottom: $grid-gutter-width;
    }

    &--md {
        margin-bottom: 20px;

        @include screen("sm-min") {
            margin-bottom: 40px;
        }
    }

    &--lg {
        margin-bottom: 50px;

        @include screen("sm-min") {
            margin-bottom: 100px;
        }
    }
}

.content-columns {
    @include screen("sm-min") {
        columns: 2;
        column-gap: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;

        & > *:last-child {
            margin-bottom: 0;
        }
    }
}

.content-narrow {
    max-width: 322px;
    margin-left: auto;
    margin-right: auto;

    @include screen("sm-min") {
        .box {
            margin-bottom: 45px;
        }
    }
}
