// Responsive Breakpoints
@mixin screen($size) {
    @if ($size == 'xs-min') {
        @media (min-width: em($screen-xs)) { @content; }
    }

    @else if ($size == 'sm-min') {
        @media (min-width: em($screen-sm)) { @content; }
    }

    @else if ($size == 'md-min') {
        @media (min-width: em($screen-md)) { @content; }
    }

    @else if ($size == 'lg-min') {
        @media (min-width: em($screen-lg)) { @content; }
    }

    @else if ($size == 'xl-min') {
        @media (min-width: em($screen-xl)) { @content; }
    }

    @else if ($size == 'breakpoint-nav-min') {
        @media (min-width: em($breakpoint-nav)) { @content; }
    }


    @else if ($size == 'xs-max') {
        @media (max-width: em($screen-xs - 1)) { @content; }
    }

    @else if ($size == 'sm-max') {
        @media (max-width: em($screen-sm - 1)) { @content; }
    }

    @else if ($size == 'md-max') {
        @media (max-width: em($screen-md - 1)) { @content; }
    }

    @else if ($size == 'lg-max') {
        @media (max-width: em($screen-lg - 1)) { @content; }
    }

    @else if ($size == 'xl-max') {
        @media (max-width: em($screen-xl - 1)) { @content; }
    }

    @else if ($size == 'breakpoint-nav-max') {
        @media (max-width: em($breakpoint-nav - 1)) { @content; }
    }

    /*
     * Only certain screen size
     */
     @else if ($size == 'xs') {
         @media (min-width: em($screen-xs)) and (max-width: em($screen-sm - 1)) { @content; }
     }
     @else if ($size == 'sm') {
         @media (min-width: em($screen-sm)) and (max-width: em($screen-md - 1)) { @content; }
     }
     @else if ($size == 'md') {
         @media (min-width: em($screen-md)) and (max-width: em($screen-lg - 1)) { @content; }
     }

     @else if ($size == 'lg') {
         @media (min-width: em($screen-lg)) and (max-width: em($screen-xl - 1)) { @content; }
     }
}



$browser-context: 16; // Default
// PX to EM
@function em($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
    $context: $context * 1px;
    }

    @return $pixels / $context * 1em;
}

// PX to REM
@function rem($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
    $context: $context * 1px;
    }

    @return $pixels / $context * 1rem;
}
